import {Helmet} from 'react-helmet';
import {Box, Container, Grid} from '@mui/material';
import TotalUsers from "../components/dashboard/TotalUsers";
import TotalArtists from "../components/dashboard/TotalArtists";
import TotalFestivals from "../components/dashboard/TotalFestivals";
import TotalEvents from "../components/dashboard/TotalEvents";

const Dashboard = () => (
  <>
    <Helmet>
      <title>Dashboard | Bass Gremlin</title>
    </Helmet>
    <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalArtists/>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalFestivals />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalEvents />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalUsers/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Dashboard;
