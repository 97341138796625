import {api} from './apiConfig'; //export const api = 'https://localhost:7200/api';
import { auth } from '../auth'; //getUser: () => {return JSON.parse(sessionStorage.getItem('user'));}

const pageSizeOptions = [5,15,50];

export const accountController = {
  getAll: (filter, pageNumber, pageSize) => {
    return new Promise((resolve, reject) => {
      if (!pageSizeOptions.find(x => x===pageSize)) reject('Invalid page size.');
      if (pageNumber < 0) reject('Invalid page number.');
      const search = filter.length > 0 ? `search=${filter}&` : '';
      const apiURL = `${api}/account?${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(apiURL, requestOptions)
        .then(response => response.json())
        .then(page => {
          resolve(page);
        })
        .catch(e => {
          reject('Internal Server Error');
          console.log(e);
        });
    })
  },
  get: (username) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(`${api}/account/${username}`, requestOptions)
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(e => reject(e));
    })
  },
  getNew: () => {
    return {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      private: false,
      gender: '',
      img: '',
      thumbnail: '',
      dateOfBirth: '',
      token: null,
    }
  },
  update: (userData) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(userData)
      }
      fetch(`${api}/account`, requestOptions)
        .then(() => {
          const storeUser = auth.getUser();
          storeUser.firstName = userData.firstName;
          storeUser.lastName = userData.lastName;
          sessionStorage.setItem('user', JSON.stringify(storeUser));
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to update. Server Error');
        });
    })
  },
  updateImage: (imageData) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(imageData)
      }
      fetch(`${api}/account/image`, requestOptions).then(() => {
        resolve('Success');
      }).catch(e => {
        console.error(e);
        reject('Failed to save Image');
      });
    })
  },
  changeRole: (email, newRole) => {
    return new Promise((resolve, reject) => {

    })
  }
}
