import React, { useState } from 'react';
import { Card, CardHeader, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PropTypes from 'prop-types';

const CollapsibleCard = (props) => {
  const [open, setOpen] = useState(props.defaultOpen);
  return (
    <Card sx={{...props.sx}}>
      <CardHeader
        title={props.title}
        onClick={() => setOpen(!open)}
        action={<IconButton>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>}
      />
      { open && props.children }
    </Card>
  )
}

CollapsibleCard.propTypes = {
  defaultOpen: PropTypes.bool,
  title: PropTypes.node,
  sx: PropTypes.object
}

export default CollapsibleCard;
