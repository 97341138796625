import {
  Box,
  Container
} from '@mui/material';
import {useParams} from "react-router-dom";
import UserDetails from '../components/user/UserDetails';

const UserPage = () => {
  let { id } = useParams()

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth="lg">
          <UserDetails id={id} />
        </Container>
      </Box>
    </>
  );

};

export default UserPage;
