import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InputIcon from '@mui/icons-material/Input';
import Logo from './Logo';
import GremlinNormal from './helpers/GremlinNormal';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([]);
  const hide = true;

  return (
    <AppBar elevation={0}{...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <GremlinNormal size={46} />
            <div style={{width: 16}} />
            <Logo />
          </Box>
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {hide ? null :
          <>
          <Hidden xlDown>
            <IconButton color="inherit" size="large">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" size="large">
              <InputIcon />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
          <MenuIcon />
          </IconButton>
          </Hidden>
          </>
        }
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
