import React, {useState} from 'react';
import {auth} from "./auth";
import Content from './components/Content';
import Login from './pages/Login';
import {Backdrop, CircularProgress} from "@mui/material";

const LoginCheck = () => {
  const [isCheckingLogin] = useState(false);
  const [user, setUser] = useState(auth.getUser);

  const updateUser = (newUser) => {
    if (newUser.role === 'Admin' || newUser.role === 'Super-Admin')
    {
      const store = JSON.stringify(newUser);
      sessionStorage.setItem('user', store);
      setUser(newUser);
    }
    else {
      setUser(null);
      sessionStorage.removeItem('user');
    }
  }

  if (isCheckingLogin){
    return <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  } else {
    return (
      user && (user.role === 'Admin' || user.role === 'Super-Admin') ? <Content /> : <Login updateUser={updateUser} />
    );
  }
}

export default LoginCheck;
