import React from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {auth} from "../../auth";
import { useNavigate } from 'react-router';

const SidebarAccountDisplay = () => {
  let navigate = useNavigate();
  const user = auth.getUser();

  const handleLogout = () => {
    auth.logout(navigate);
  }

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2 }}>
      <Avatar
        component={RouterLink}
        src={user ? user.thumbnail : ''}
        sx={{ cursor: 'pointer', width: 128, height: 128, marginBottom: 1.5}}
        to="/app/account"
      />
      <Typography color="textSecondary" variant="body2">
        {user ? user.firstName : 'not logged in'}
      </Typography>
      <Button onClick={handleLogout}>Logout</Button>
    </Box>
  );
}

export default SidebarAccountDisplay;
