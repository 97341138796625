import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { auth } from '../../auth';
import React, { createRef } from 'react';
import { accountController } from '../../controllers/accountController';

const AccountProfile = () => {
  const user = auth.getUser();
  const inputRef = createRef();

  const handleImageChange = e => {
    if (e.target.files[0]){
      const image = e.target.files[0];
      handleImageUpload(image);
    }
  }

  const handleImageUpload = (image) => {
    if (image){
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded;
      reader.readAsBinaryString(image);
    }
  }

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    sendImageRequest(btoa(binaryString));
  }

  const sendImageRequest = (imageData) => {
    accountController.updateImage(imageData)
      .then(() => {
        accountController.get(user.username)
          .then(publicUser => {
            const u = user;
            u.img = publicUser.img;
            u.thumbnail = publicUser.thumbnail;
            const save = JSON.stringify(u);
            sessionStorage.setItem('user', save);
            window.location.reload();
          })
      }).catch(e => console.error(e));
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <Avatar
            src={user ? user.img : ''}
            sx={{height: 256, width: 256, marginBottom: 2}}
          />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {user.username}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${user.email}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text" onClick={() => inputRef.current?.click()}>
          Upload picture
        </Button>
        <input
          type='file'
          multiple={false}
          accept='.jpg,.jpeg'
          hidden
          ref={inputRef}
          onChange={handleImageChange}/>
      </CardActions>
    </Card>
  );
}


export default AccountProfile;
