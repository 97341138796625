import React, {Component} from "react";
import { eventController } from '../../controllers/eventController';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, Chip,
  Divider,
  TextField,
} from '@mui/material';
import { artistController } from '../../controllers/artistController';
import { festivalController } from '../../controllers/festivalController';
import CollapsibleCard from '../helpers/CollapsibleCard';

class TagBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      text: '',
    }
    this.id = props.id;
    this.controller = this._getController(props.type);
    this.handleTextChange.bind(this);
    this.removeTag.bind(this);
  }

  componentDidMount() {
    this.fetchTags();
  }

  fetchTags() {
    const controller = this.controller;
    if (controller){
      controller.tags.get(this.id).then(result => {
        const tags = result ? result : [];
        this.setState({ tags, text: '' });
      }).catch(e => console.error(e));
    }
  }

  addTag = () => {
    const controller = this.controller;
    if (!controller) return;
    const name = this.state.text.toUpperCase().replaceAll(' ', '');
    controller.tags.add(this.id, name)
      .then(() => this.fetchTags())
      .catch(e => console.error(e));
  }

  removeTag = (tag) => {
    const controller = this.controller;
    if (!controller) return;
    controller.tags.remove(this.id, tag)
      .then(() => this.fetchTags())
      .catch(e => console.error(e));
  }

  handleTextChange = (e) => {
    const text = e.target.value.toUpperCase().replaceAll(' ', '');
    this.setState({text});
  }

  render() {
    return (
      <CollapsibleCard title='Tags' defaultOpen={false} sx={{marginBottom: 1}}>
        <CardContent>
          <Box>
            <Box sx={{marginBottom: 1, display: 'flex', alignItems: 'center'}}>
              <TextField
                id='tag-input'
                label='Add Tag'
                value={this.state.text}
                onChange={this.handleTextChange}
                variant='outlined'
                size='small'
              />
              <Button
                onClick={() => this.addTag()}
                disabled={this.state.text.length <= 2}
              >Add</Button>
            </Box>
            <Divider sx={{marginBottom: 1}} />
            <Box sx={{display: 'flex', flexWrap: 'wrap'}} >
              {this.state.tags.map(tag =>
                <Chip key={tag} sx={{margin: .5}} label={tag} onDelete={() => this.removeTag(tag)} />
              )}
            </Box>
          </Box>
        </CardContent>
      </CollapsibleCard>
    );
  }

  _getController = (type) => {
    let controller;
    switch (type){
      case 'event':
        controller = eventController;
        break;
      case 'artist':
        controller = artistController;
        break;
      case 'festival':
        controller = festivalController;
        break;
    }
    return controller;
  }
}

export default TagBox;
