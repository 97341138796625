import React, {Component} from "react";
import {
  Autocomplete,
  Avatar, Backdrop,
  Box,
  Button,
  Card, CardContent,
  CardHeader, CircularProgress, IconButton, Stack,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow, TextField, Typography
} from '@mui/material';
import {NavLink} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import getInitials from "../../utils/getInitials";
import {eventController} from "../../controllers/eventController";
import {artistController} from "../../controllers/artistController";
import {SocialLinks} from "../social/SocialLinks";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CollapsibleCard from '../helpers/CollapsibleCard';

class EventLineup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: true,
      artistToSave: null,
      tierToSave: null,
      artistOptions: [],
      isLoading: false,
      page: {
        items: [],
        totalPages: 0,
        itemsFrom: 0,
        itemsTo: 0,
        totalItemsCount: 0
      },
      filter: '',
      pageSize: 15,
      pageNumber: 0,
      disconnected: false,
    }
    this.id = props.id;
    this.handleLimitChange.bind(this);
    this.handlePageChange.bind(this);
    this.handleAddArtistToLineup.bind(this);
    this.removeArtistFromLineup.bind(this);
    this.updateArtistOptions.bind(this);
    this.fetchArtists.bind(this);
    this.fetchArtistOptions.bind(this);
  }

  componentDidMount() {
    this.fetchArtists(this.state.pageNumber, this.state.pageSize);
  }

  fetchArtists(pageNumber, pageSize) {
    eventController.lineup.get(this.id, '', pageNumber, pageSize)
      .then(page => this.setState({page, disconnected: false}))
      .catch(e => {
        this.setState({disconnected: true})
        console.log(e);
      });
  }

  fetchArtistOptions = (search) => {
    artistController.getAll(search, 0, 50)
      .then(page => this.setState({artistOptions: page.items}))
      .catch(e => console.log(e));
  }

  handleLimitChange = e => {
    const pageSize = e.target.value;
    this.setState({pageSize});
    this.fetchArtists(this.state.pageNumber, pageSize);
  }

  handlePageChange = (e, newPage) => {
    this.setState({pageNumber: newPage});
    this.fetchArtists(newPage, this.state.pageSize);
  }

  updateArtistOptions = e => {
    if (!e) return;
    const search = e.target.value;
    if (search && search.length > 0){
      this.fetchArtistOptions(search);
    } else {
      this.setState({artistOptions: []});
    }
  }

  handleAddArtistToLineup = () => {
    this.setState({isLoading: true});
    const artist = this.state.artistToSave;
    const tier = this.state.tierToSave;
    const lineupArtists = this.state.page.items;
    const match = lineupArtists.find(a => a.id === artist.id);
    if (match){
      this.setState({isLoading: false});
      alert('You already added this artist');
      this.setState({artistToSave: null, tierToSave: null});
    }else{
      eventController.lineup.add(this.id, artist.id, tier).then(() => {
        const page = this.state.page;
        artist.tier = tier;
        page.items.push(artist);
        this.setState({page, artistToSave: null, tierToSave: null, artistOptions: [], isLoading: false});
      })
      .catch(e => {
        console.log(e);
        this.setState({artistToSave: null, tierToSave: null, artistOptions: [], isLoading: false});
      });
    }
  }

  removeArtistFromLineup = (artist, index) => {
    eventController.lineup.remove(this.id, artist.id).then(() => {
      const page = this.state.page;
      page.items.splice(index, 1);
      this.setState({page});
    }).catch(e => console.log(e));
  }

  render() {
    const page = this.state.page;
    const artistOptions = this.state.artistOptions;
    const tierOptions = [1,2,3];

    return (
      <CollapsibleCard title={`Lineup (${page.totalItemsCount})`} sx={{marginBottom: 1}} defaultOpen={false}>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CardContent>
          <Stack direction='row' spacing={2}>
            {artistOptions ?
              <Autocomplete
                disablePortal
                id='artist-select'
                value={this.state.artistToSave}
                options={this.state.artistOptions}
                onChange={(event, newValue) => this.setState({ artistToSave: newValue })}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Add Artist" />}
                onInputChange={this.updateArtistOptions}
                getOptionLabel={(option) => option.name}
              />
              : null}
            <Autocomplete
              disablePortal
              id='artist-tier-select'
              value={this.state.tierToSave}
              options={tierOptions}
              sx={{ width: 100 }}
              onChange={(event, newValue) => this.setState({ tierToSave: newValue })}
              renderInput={(params) => <TextField {...params} label="Tier" />}
              getOptionLabel={option => option.toString()}
            />
            {this.state.artistToSave && this.state.tierToSave ?
              <Button onClick={this.handleAddArtistToLineup}>
                Add Artist to Lineup
              </Button>
              : null
            }
          </Stack>
        </CardContent>
          <TablePagination
            component="div"
            count={page.totalItemsCount}
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleLimitChange}
            page={this.state.pageNumber}
            rowsPerPage={this.state.pageSize}
            rowsPerPageOptions={[5, 15, 50]}
          />
          <PerfectScrollbar>
            <Box sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Artist</TableCell>
                    <TableCell>Tier</TableCell>
                    <TableCell>Genres</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {page.items.map((artist, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Box sx={{ alignItems: 'center', display: 'flex' }}>
                          <Avatar src={artist.thumbnail} sx={{ mr: 2 }}>
                            {getInitials(artist.name)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            <NavLink to={`/app/artists/${artist.id}`} style={{ width: '100%' }}>
                              {artist.name}
                            </NavLink>
                            <SocialLinks entity={artist} size={22} />
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{artist.tier}</TableCell>
                      <TableCell>{artist.genres.map((genre, index) => <span
                        key={index}>{genre}{index < artist.genres.length - 1 ? ', ' : ''}</span>)}</TableCell>
                      <TableCell>
                        <Button onClick={() => this.removeArtistFromLineup(artist, index)}>
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={page.totalItemsCount}
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleLimitChange}
            page={this.state.pageNumber}
            rowsPerPage={this.state.pageSize}
            rowsPerPageOptions={[5, 15, 50]}
          />
      </CollapsibleCard>
    );
  }
}

export default EventLineup;
