import {
  Box,
  Container
} from '@mui/material';
import {useParams} from "react-router-dom";
import EventDetails from '../components/event/EventDetails';
import React from "react";

const EventPage = () => {
  let { id } = useParams()

  return (
    <>
      <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
        <Container maxWidth="lg">
          <EventDetails id={id}/>
        </Container>
      </Box>
    </>
  );

};

export default EventPage;
