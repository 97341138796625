import React, {Component, createRef} from "react";
import {
  Alert, Avatar, Box, Card,
  CardContent, CardHeader, Collapse, Typography
} from '@mui/material';
import getInitials from "../../utils/getInitials";
import {Helmet} from "react-helmet";
import { accountController } from '../../controllers/accountController';

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      user: null,
      showSuccessAlert: false,
      showImageSavedAlert: false,
    }
  }

  componentDidMount() {
    accountController.get(this.state.id).then(user => this.setState({user})).catch(e => console.log(e));
  }

  render() {
    const user = this.state.user;
    if (user){
      return (
        <>
          <Helmet>
            <title>{user.username} | Bass Gremlin</title>
          </Helmet>
          <Collapse in={this.state.showSuccessAlert}>
            <Alert hidden={true} sx={{marginBottom: 2}} severity='success'>User Details Saved</Alert>
          </Collapse>
          <Collapse in={this.state.showImageSavedAlert}>
            <Alert hidden={true} sx={{marginBottom: 2}} severity='success'>User Image Saved</Alert>
          </Collapse>
          <Card sx={{marginBottom: 1}}>
            <CardHeader title={user.username} />
            <CardContent>
              <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Typography variant='h2'>{user.firstName} {user.lastName}</Typography>
                  <Avatar
                    sx={{width: 240, height: 240 , margin: 2, fontSize: 'xxx-large'}}
                    src={user.img}
                  >
                    {getInitials(user.username)}
                  </Avatar>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </>
      );
    } else{
      return <></>
    }
  }
}

export default UserDetails;
