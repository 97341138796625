import React, {useRef, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import {NavLink} from "react-router-dom";
import { csvToObjectArray } from '../../utils/csvConverter';

function FestivalListToolbar (props) {
  const [festivalUpload, setFestivalUpload] = useState([]);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const fileImportRef = useRef();
  const reader = new FileReader();
  const setFilter = props.setFilter;
  const filter = props.filter;

  const handleImportRefChange = e => {
    const file = e.target.files[0];
    reader.onload = function(event) {
      const data = csvToObjectArray(event.target.result);
      setFestivalUpload(data);
      console.log(data);
      setShowImportDialog(true);
    };
    reader.readAsText(file);
  }

  const handleSaveImport = () => {
    if (!festivalUpload)
      setShowImportDialog(false);
  }

  return (
    <Box {...props}>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h2' color='primary'>Festivals</Typography>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={() => fileImportRef.current.click()}>
            Import
          </Button>
          <input onChange={handleImportRefChange} multiple={false} ref={fileImportRef} type='file' accept='.csv' hidden/>
          <Button sx={{ mx: 1 }}>
            Export
          </Button>
          <NavLink to={`/app/festivals/new`}>
            <Button color="primary" variant="contained">
              Add festival
            </Button>
          </NavLink>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search festival"
                variant="outlined"
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={showImportDialog}>
        <DialogTitle>
          Validate Festival Import
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>name</TableCell>
                <TableCell>location</TableCell>
                <TableCell>camping</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {festivalUpload.map((festival) =>
                <TableRow key={festival.id}>
                  <TableCell>{festival.id}</TableCell>
                  <TableCell>{festival.name}</TableCell>
                  <TableCell>{festival.location}</TableCell>
                  <TableCell>{festival.camping}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setShowImportDialog(false)}}>
            Cancel
          </Button>
          <Button onClick={handleSaveImport}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}


export default FestivalListToolbar;
