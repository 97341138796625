import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import CustomerListToolbar from '../components/artist/ArtistListToolbar';
import UserListResults from '../components/user/UserListResults';
import { auth } from '../auth';
import { accountController } from '../controllers/accountController';
import UserListToolbar from '../components/user/UserListToolbar';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        items: [],
        totalPages: 0,
        itemsFrom: 0,
        itemsTo: 0,
        totalItemsCount: 0
      },
      filter: '',
      pageSize: 15,
      pageNumber: 0,
      disconnected: false,
      loading: false
    }
    this.user = auth.getUser();
    this.setFilter.bind(this);
    this.setPageSize.bind(this);
    this.setPageNumber.bind(this);
  }

  componentDidMount() {
    this.fetchUsers(this.state.filter, this.state.pageNumber, this.state.pageSize)
  }

  fetchUsers(filter, pageNumber, pageSize){
    this.setState({loading: true});
    accountController.getAll(filter,pageNumber,pageSize)
      .then(page => this.setState({page, disconnected: false, loading: false}))
      .catch(e => {
        this.setState({disconnected: true, loading: true})
        console.error(e);
      });
  }

  setFilter = (filter) => {
    this.setState({filter});
    this.setState({pageNumber: 0});
    this.fetchUsers(filter, this.state.pageNumber, this.state.pageSize);
  }

  setPageSize = (pageSize) => {
    this.setState({pageSize});
    this.fetchUsers(this.state.filter, this.state.pageNumber, pageSize);
  }

  setPageNumber = (pageNumber) => {
    this.setState({pageNumber});
    this.fetchUsers(this.state.filter, pageNumber, this.state.pageSize);
  }

  render(){
    return (
      <>
        <Helmet>
          <title>Users | Bass Gremlin</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
          <Container maxWidth={false}>
            <UserListToolbar filter={this.state.filter} setFilter={this.setFilter} />
            <Box sx={{ pt: 3 }}>
              <UserListResults
                page={this.state.page}
                setPageSize={this.setPageSize}
                setPageNumber={this.setPageNumber}
                pageSize={this.state.pageSize}
                pageNumber={this.state.pageNumber}
                loading={this.state.loading}
              />
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

export default UserList;
