import React, {Component, createRef} from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, Collapse,
  Divider,
  Grid, Snackbar,
  TextField, Typography
} from '@mui/material';
import getInitials from "../../utils/getInitials";
import {Helmet} from "react-helmet";
import {validateArtist} from "../../utils/formSubmitValidators";
import ArtistEventList from "./ArtistEventList";
import {artistController} from "../../controllers/artistController";
import TagBox from '../tags/TagBox';
import PostList from '../post/PostList';
import NotFound from '../helpers/NotFound';
import CollapsibleCard from "../helpers/CollapsibleCard";

class ArtistDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      artist: null,
      events: [],
      uploadImage: null,
      previewImage: '',
      allGenres: [],
      notFound: false,
      snackbarOpen: false,
      snackbarMessage: '',
    }
    this.inputReference = createRef();
    this.handleImageChange.bind(this);
    this.handleImageUpload.bind(this);
    this.handleSaveDetails.bind(this);
    this.addGenre.bind(this);
    this.removeGenre.bind(this);
    this.deleteArtist.bind(this);
    this.handleArtistInputChange.bind(this);
  }

  componentDidMount() {
    this.getAllGenres();
    if (this.state.id === 'new'){
      this.setState({artist: artistController.getNew()});
      return;
    }

    this.fetchArtist();
    artistController.events.getAll(this.state.id).then(events => this.setState({events})).catch(e => console.log(e));
  }

  fetchArtist(){
    artistController.get(this.state.id)
      .then(artist => {
        if (artist.status === 404){
          this.setState({artist: null, notFound: true});
          return;
        }
        this.setState({ artist });
      })
      .catch(e => console.log(e));
  }

  getAllGenres = () => {
    artistController.genres.getAll().then(allGenres => this.setState({allGenres})).catch(e => console.log(e));
  }

  handleImageChange = e => {
    if (e.target.files[0]){
      const image = e.target.files[0];
      this.setState({
        uploadImage: image,
        previewImage: URL.createObjectURL(image),
      })
    }
  }

  handleImageUpload = async () => {
    const image = this.state.uploadImage;

    if (image){
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(image);
    }
  }

  sendImageRequest = (imageData) => {
    artistController.updateImage(this.state.id, imageData)
      .then(() => this.setState({snackbarOpen: true, snackbarMessage: 'Image Saved!'}))
      .catch(() => alert('Failed to save image'));
  }

  addGenre = genre => {
    const artist = this.state.artist;
    artist.genres.push(genre);
    artistController.genres.add(this.state.id, genre)
      .then(() => this.setState({artist}))
      .catch(e => alert(e));
  }

  removeGenre = genre => {
    const artist = this.state.artist;
    artist.genres.splice(artist.genres.indexOf(genre), 1);
    artistController.genres.remove(this.state.id, genre)
      .then(() => this.setState({artist}))
      .catch(e => alert(e));
  }

  handleArtistInputChange = e => {
    let artist = this.state.artist;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    artist[name] = value;
    this.setState({artist});
  }

  handleSaveDetails = () => {
    const artist = this.state.artist;
    const valid = validateArtist(artist);
    if (valid.valid === false){
      alert(valid.error);
      return;
    }

    if (artist.id === 'new'){
      artistController.add(artist).then(newArtist => {
        this.setState({id: newArtist.id, artist: newArtist});

      })
      .catch(e => console.log(e));
      return;
    }

    artistController.update(artist).then(() => this.setState({snackbarOpen: true, snackbarMessage: 'Artist Details Saved!'})).catch(e => console.log(e));
  }

  deleteArtist = () =>
    artistController.delete(this.state.artist)
      .then(() => this.setState({id: 'new', artist: artistController.getNew(), events: []}))
      .catch(e => console.log(e));

  render() {
    if (this.state.notFound){
      return (
        <NotFound entityType='Artist' />
      );
    }

    if (this.state.artist){
      const artist = this.state.artist;
      const img = this.state.previewImage ? this.state.previewImage : this.state.artist.img;
      return (
        <>
          <Helmet>
            <title>{artist.name} | Bass Gremlin</title>
          </Helmet>
          {artist.id === 'new' ? null :
          <Card sx={{marginBottom: 1}}>
            <CardContent>
              <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Typography variant='h2'>{artist.name}</Typography>
                  <Avatar
                    sx={{width: 240, height: 240 , margin: 2, fontSize: 'xxx-large'}}
                    src={img}
                  >
                    {getInitials(artist.name)}
                  </Avatar>
                  <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {this.state.previewImage ?
                      <Button variant="contained" onClick={this.handleImageUpload}>
                        Save Image
                      </Button>
                      :
                      <Button color="primary" variant="contained" onClick={() => this.inputReference.current?.click()}>
                        Change Image
                      </Button>
                    }
                    <input
                      type='file'
                      multiple={false}
                      accept='.jpg,.jpeg'
                      hidden
                      ref={this.inputReference}
                      onChange={this.handleImageChange}/>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
          }
          <form autoComplete="off" noValidate>
            <Card sx={{marginBottom: 1}}>
              <CardHeader title="Artist Profile" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="name"
                      fullWidth
                      label="Name"
                      onChange={this.handleArtistInputChange}
                      required
                      value={artist.name}
                      variant="outlined"
                    />
                  </Grid>
                  {this.state.id === 'new' ? null :
                    <Grid item md={6} xs={12}>
                      {this.state.allGenres.map(genre =>
                          <span key={genre} style={{margin: 2}}>
                        {
                          artist.genres.includes(genre) ?
                            <Button variant="contained" onClick={() => this.removeGenre(genre)}>{genre}</Button>
                            :
                            <Button variant="outlined" onClick={() => this.addGenre(genre)}>{genre}</Button>
                        }
                      </span>
                      )}
                    </Grid>
                  }
                  <Grid item md={12} xs={12}>
                    <TextField
                      name='about'
                      fullWidth
                      labelid='about'
                      label='About'
                      multiline
                      value={artist.about}
                      onChange={this.handleArtistInputChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button color="primary" variant="contained" onClick={this.handleSaveDetails}>
                  Save details
                </Button>
              </Box>
            </Card>
          </form>
          { artist.id === 'new' ? null :
            <>
              <CollapsibleCard title='Links' sx={{marginBottom: 1}} defaultOpen={false}>
                <Box sx={{display: 'flex', flexDirection: 'column', padding: 2}}>
                  <TextField value={artist.spotify ? artist.spotify : ''} onChange={this.handleArtistInputChange} size="small" name="spotify" label="Spotify Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.appleMusic ? artist.appleMusic : ''} onChange={this.handleArtistInputChange} size="small" name="appleMusic" label="Apple Music Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.youTubeMusic ? artist.youTubeMusic : ''} onChange={this.handleArtistInputChange} size="small" name="youTubeMusic" label="YouTube Music Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.soundCloud ? artist.soundCloud : ''} onChange={this.handleArtistInputChange} size="small" name="soundCloud" label="SoundCloud Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.facebook ? artist.facebook : ''} onChange={this.handleArtistInputChange} size="small" name="facebook" label="Facebook Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.instagram ? artist.instagram : ''} onChange={this.handleArtistInputChange} size="small" name="instagram" label="Instagram Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.twitter ? artist.twitter : ''} onChange={this.handleArtistInputChange} size="small" name="twitter" label="Twitter Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.youTube ? artist.youTube : ''} onChange={this.handleArtistInputChange} size="small" name="youTube" label="YouTube Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.tikTok ? artist.tikTok : ''} onChange={this.handleArtistInputChange} size="small" name="tikTok" label="TikTok Link" sx={{marginBottom: 2}}/>
                  <TextField value={artist.website ? artist.website : ''} onChange={this.handleArtistInputChange} size="small" name="website" label="Website Link" sx={{marginBottom: 2}}/>
                </Box>
              </CollapsibleCard>
              <TagBox id={artist.id} type='artist' />
              <ArtistEventList events={this.state.events} />
              <PostList id={artist.id} type='artists' />
              <Button
                variant='contained'
                color='primary'
                sx={{marginTop: 5}}
                onClick={this.deleteArtist}
              >Delete Artist</Button>
            </>
          }
          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={this.state.snackbarOpen}
            autoHideDuration={3000}
            onClose={() => this.setState({snackbarOpen: false})}
            message={this.state.snackbarMessage}
          />
        </>
      );
    } else{
      return <></>
    }
  }

  _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.sendImageRequest(btoa(binaryString));
  }
}

export default ArtistDetails;
