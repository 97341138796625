import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import {Box, Container, Typography} from '@mui/material';
import ArtistListResults from '../components/artist/ArtistListResults';
import ArtistListToolbar from "../components/artist/ArtistListToolbar";
import {artistController} from "../controllers/artistController";

class ArtistList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        items: [],
        totalPages: 0,
        itemsFrom: 0,
        itemsTo: 0,
        totalItemsCount: 0
      },
      filter: '',
      pageSize: 15,
      pageNumber: 0,
      disconnected: false,
      loading: false,
    }
    this.setFilter.bind(this);
    this.getArtists.bind(this);
    this.handlePageSizeChange.bind(this);
    this.handlePageNumberChange.bind(this);
  }

  componentDidMount () {
    this.getArtists(this.state.filter, this.state.pageNumber, this.state.pageSize);
  }

  getArtists (filter, pageNumber, pageSize) {
    this.setState({loading: true})
    artistController.getAll(filter, pageNumber, pageSize)
      .then(page => this.setState({page, disconnected: false, loading: false}))
      .catch(e => {
        console.log(e);
        this.setState({disconnected: true, loading: false});
      });
  }

  setFilter = (filter) => {
    this.setState({filter});
    this.setState({pageNumber: 0});
    this.getArtists(filter, this.state.pageNumber, this.state.pageSize);
  }

  handlePageSizeChange = (pageSize) => {
    this.setState({pageSize});
    this.getArtists(this.state.filter, this.state.pageNumber, pageSize);
  }

  handlePageNumberChange = (pageNumber) => {
    this.setState({pageNumber});
    this.getArtists(this.state.filter, pageNumber, this.state.pageSize);
  }

  render(){
    const filter = this.state.filter;
    const setFilter = this.setFilter;

    return (
      <>
        <Helmet>
          <title>Artists | Bass Gremlin</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
          <Container maxWidth={false}>
            <ArtistListToolbar filter={filter} setFilter={setFilter} />
            <Box sx={{ pt: 3 }}>
              {
                this.state.disconnected ?
                  <Typography>Failed to connect to the server.</Typography>
                  :
                  <>
                  {this.state.page && this.state.page.items ?
                      <ArtistListResults
                        page={this.state.page}
                        handlePageNumberChange={this.handlePageNumberChange}
                        handlePageSizeChange={this.handlePageSizeChange}
                        pageSize={this.state.pageSize}
                        pageNumber = {this.state.pageNumber}
                        loading={this.state.loading}
                      />
                      : null
                  }
                  </>
              }
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

export default ArtistList;
