import {NavLink} from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card, CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import getInitials from '../../utils/getInitials';
import {SocialLinks} from "../social/SocialLinks";
import Pagination from '../helpers/Pagination';

const ArtistListResults = ({ page, pageSize, pageNumber, handlePageSizeChange, handlePageNumberChange, loading, ...rest }) => {

  const handleLimitChange = (event) => {
    handlePageSizeChange(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    handlePageNumberChange(newPage);
  };

  if (loading){
    return <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
      <CircularProgress />
    </Box>
  }

  return (
    <Card {...rest}>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Genre</TableCell>
                <TableCell>PlurCount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.items.map((artist) => (
                  <TableRow hover key={artist.id}>
                    <TableCell>
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Avatar src={artist.thumbnail} sx={{ mr: 2, width: 48, height: 48 }}>
                          {getInitials(artist.name)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1" sx={{marginRight: 1}}>
                          <NavLink to={`/app/artists/${artist.id}`} style={{width: '100%'}}>
                            {artist.name}
                          </NavLink>
                          <SocialLinks entity={artist} size={22} />
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{artist.genres.map((genre,index) => <span key={index}>{genre}{index < artist.genres.length - 1 ? ', ': ''}</span>)}</TableCell>
                    <TableCell>{artist.plurCount}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
    </Card>
  );
};

export default ArtistListResults;
