import {api} from './apiConfig'; //export const api = 'https://localhost:7200/api';
import { auth } from '../auth'; //getUser: () => {return JSON.parse(sessionStorage.getItem('user'));}

const pageSizeOptions = [5,15,50];

export const festivalController = {
  getAll: (filter, pageNumber, pageSize) => {
    return new Promise((resolve, reject) => {
      if (!pageSizeOptions.find(x => x===pageSize)) reject('Invalid page size.');
      if (pageNumber < 0) reject('Invalid page number.');
      const search = filter.length > 0 ? `search=${filter}&` : '';
      const apiURL = `${api}/festivals?${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      fetch(apiURL)
        .then(response => response.json())
        .then(page => {resolve(page);})
        .catch(e => {
          reject('Internal Server Error');
          console.log(e);
        });
    })
  },
  get: (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${api}/festivals/${id}`).then(response => response.json())
        .then(festival => resolve(festival))
        .catch(e => {
          console.log(e);
          reject('Failed to load. Server Error.');
        });
    })
  },
  getNew: () => {
    return {
      id: 'new',
      name: 'New Festival',
      city: '',
      state: '',
      country: '',
      about: '',
      img: '',
      plurCount: 0,
      facebook: '',
      instagram: '',
      twitter: '',
      website: '',
      tickets: '',
    }
  },
  add: (festival) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(festival)
      }
      fetch(`${api}/festivals`, requestOptions)
        .then(() => {
          festival.id = festival.name.toLowerCase().replaceAll(' ','-');
          resolve(festival);
        })
        .catch(e => {
          console.log(e);
          reject('Failed to create. Server Error');
        });
    })
  },
  update: (festival) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(festival)
      }
      fetch(`${api}/festivals/${festival.id}`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to update. Server Error');
        });
    })
  },
  delete: (festival) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(`${api}/festivals/${festival.id}`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to Delete. Server Error.');
        });
    });
  },
  updateImage: (id, imageData) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');

      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(imageData)
      }

      fetch(`${api}/festivals/${id}/image`, requestOptions).then(() => {
        resolve('Success');
      }).catch(e => {
        console.log(e);
        reject('Failed to save Image');
      });
    })
  },
  events: {
    get: (festivalId, pageNumber, pageSize) => {
      return new Promise((resolve, reject) => {
        fetch(`${api}/festivals/${festivalId}/events?pageNumber=${pageNumber}&pageSize=${pageSize}`)
          .then(response => response.json())
          .then(page => resolve(page))
          .catch(e => {
            console.log(e);
            reject('Server Error');
          });
      })
    }
  },
  tags: {
    get: (id) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'GET',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/festivals/${id}/tags`, requestOptions)
          .then(response => response.json())
          .then(result => resolve(result))
          .catch(e => {
            console.log(e);
            reject('Failed to get tags. Server Error.');
          });
      })
    },
    add: (id, name) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/festivals/${id}/addTag/${name}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
    remove: (id, name) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/festivals/${id}/removeTag/${name}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
  }
}
