import React, {Component} from "react";
import {Avatar, Card, CardContent, Grid, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import PeopleIcon from "@mui/icons-material/People";
import {NavLink} from "react-router-dom";
import { accountController } from '../../controllers/accountController';

class TotalUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCount: 0,
    }
  }

  componentDidMount() {
    accountController.getAll('',0,5)
      .then(page => {this.setState({ userCount: page.totalItemsCount })});
  }

  render(){
    return (
      <Card>
        <CardContent>
          <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6">
                TOTAL USERS
              </Typography>
              <NavLink  to={`/app/users`}>
                <Typography color="textPrimary" variant="h3">
                  {this.state.userCount}
                </Typography>
              </NavLink>
            </Grid>
            <Grid item>
              <Avatar sx={{backgroundColor: blue[600], height: 56, width: 56}}>
                <PeopleIcon />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default TotalUsers;
