import {
  Box,
  Container
} from '@mui/material';
import ArtistDetails from "../components/artist/ArtistDetails";
import {useParams} from "react-router-dom";

const ArtistPage = () => {
  let { id } = useParams()

  return (
      <>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
          <Container maxWidth="lg">
            <ArtistDetails id={id}/>
          </Container>
        </Box>
      </>
    );

};

export default ArtistPage;
