import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import EventScheduleScaffold from '../components/event/schedule/EventScheduleScaffold';

const EventSchedule = () => {
  let { id } = useParams();

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth='xl'>
          <EventScheduleScaffold id={id} />
        </Container>
      </Box>
    </>
  );
}

export default EventSchedule;
