import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import ArtistList from './pages/ArtistList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Register from './pages/Register';
import Settings from './pages/Settings';
import UserList from './pages/UserList';
import ArtistPage from "./pages/ArtistPage";
import FestivalList from './pages/FestivalList';
import EventList from './pages/EventList';
import EventPage from './pages/EventPage';
import FestivalPage from "./pages/FestivalPage";
import PerformancePage from './pages/PerformancePage';
import ArtistListForCSV from "./pages/ArtistListForCSV";
import UserPage from './pages/UserPage';
import EventSchedule from './pages/EventSchedule';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'artists', element: <ArtistList /> },
      { path: 'artists/csv', element: <ArtistListForCSV /> },
      { path: 'artists/:id', element: <ArtistPage /> },
      { path: 'festivals', element: <FestivalList /> },
      { path: 'festivals/:id', element: <FestivalPage /> },
      { path: 'events', element: <EventList /> },
      { path: 'events/:id', element: <EventPage /> },
      { path: 'events/:id/schedule', element: <EventSchedule /> },
      { path: 'events/performances/:event/:id', element: <PerformancePage /> },
      { path: 'users', element: <UserList /> },
      { path: 'users/:id', element: <UserPage /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
