import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  Calendar as CalendarIcon,
  Lock as LockIcon,
  Music as MusicIcon,
  Sun as SunIcon,
  Users as UsersIcon
} from 'react-feather';
import NavItem from './NavItem';
import SidebarAccountDisplay from './account/SidebarAccountDisplay';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/festivals',
    icon: SunIcon,
    title: 'Festivals'
  },
  {
    href: '/app/events',
    icon: CalendarIcon,
    title: 'Events'
  },
  {
    href: '/app/artists',
    icon: MusicIcon,
    title: 'Artists'
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Users'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, onMobileClose, openMobile]);

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SidebarAccountDisplay />
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{sx: {width: 256}}}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{sx: {width: 256, top: 64, height: 'calc(100% - 64px)'}}}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
