import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import React from 'react';

const NotFound = ({entityType}) => (
  <>
    <Helmet>
      <title>{entityType} Not Found | Bass Gremlin</title>
    </Helmet>
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12}}>
      <img
        alt="Logo"
        src="/static/gremlin_sad.png"
        style={{height: 256}}
      />
      <Typography variant='h3' sx={{textAlign: 'center', marginTop: 4}}>I couldn't find the {entityType} you were looking for.</Typography>
      <Typography>This usually means something went wrong with the URL or Link used to get here.</Typography>
    </Box>
  </>

);

export default NotFound;
