import React, { Component, useState } from 'react';
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  Dialog, DialogActions, DialogContent,
  DialogTitle,
  Divider, IconButton, InputAdornment, TextField, Tooltip,
  Typography
} from '@mui/material';
import {addHours, addMinutes, differenceInMinutes, format, getMinutes, startOfDay} from 'date-fns';
import {AddCircle, Clear, Edit} from '@mui/icons-material';
import CollapsibleCard from '../../helpers/CollapsibleCard';
import {eventController} from "../../../controllers/eventController";
import StageImageUploader from "./StageImageUploader";
import SlotButton from "./SlotButton";

//AM time when the day switches over to the next day - instead of using 12AM, due to many shows going later than midnight
const dayStart = 5;
//Interval describes the number of minutes each selectable block of the grid should occupy. 15 minutes is the default,
//due to most performances starting at XX:00, XX:15, XX:30, XX:45, etc.
const interval = 15;
//1440 is the number of minutes in a 24 hour day
const timeSlotsPerDay = 1440 / interval;
const rowHeight = 30;
const rowOffsetColor = '#f8f8f8';

const performanceTextStyle = {
  backgroundColor: 'rgba(49,49,49,0.7)',
  color: 'white',
  paddingLeft: 1,
  paddingRight: 1,
  borderRadius: 1
}

class EventScheduleDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardOpen: false,
      hoverDay: null,
      hoverIndex: 4,
      dialogOpen: false,
      dialogSlot: null,
      artistToSave: null,
      stages: [],
      rows: [],
      lineup: [],
      artistOptions: [],
      performanceArtists: [],
      performances: [],
      stageDialogOpen: false,
      stageDialogName: '',
      stageDialogIsEdit: false,
      stageDialogEditingStage: null,
    }
    this.event = props.event;
    this.day = props.day;
    this.openDialog.bind(this);
    this.closeDialog.bind(this);
    this.generateRows.bind(this);
    this.addArtistToPerformance.bind(this);
    this.removeArtistFromPerformance.bind(this);
    this.savePerformance.bind(this);
    this.deletePerformance.bind(this);
    this.changeDialogDuration.bind(this);
    this.handleChangeStageName.bind(this);
    this.saveNewStage.bind(this);
    this.handleOpenEditStageDialog.bind(this);
    this.saveExistingStage.bind(this);
    this.deleteStage.bind(this);
    this.onStageImageUpdated.bind(this);
  }

  componentDidMount() {
    this.fetchStages();

    eventController.lineup.get(this.event.id, '', 0, 50)
      .then(page => {
        this.setState({lineup: page.items})
      });

    this.fetchPerformances();
  }

  fetchPerformances(){
    eventController.performances.get(this.event.id)
      .then(performances => this.setState({performances}))
      .catch(e => console.log(e));
  }

  fetchStages(){
    eventController.stages.get(this.event.id)
      .then(stages => {
        const rows = this.generateRows();
        this.setState({stages, rows})
      })
      .catch(e => console.log(e));
  }

  generateRows = () => {
    let time = addHours(startOfDay(this.day), dayStart);
    let rows = [];
    for (let i = 0; i < timeSlotsPerDay; i++) {
      const color = i%2 === 0 ? 'white' : rowOffsetColor;
      const show = getMinutes(time) === 0;
      rows.push({time, color, show});
      time = addMinutes(time, interval);
    }
    return rows;
  }

  openDialog = (startTime, stage) => {
    const duration = 60;
    const endTime = addMinutes(startTime, duration);
    const slot = {startTime, endTime, stage, day: this.day, duration}
    this.setState({dialogOpen: true, dialogSlot: slot});
  }

  closeDialog = () => {
    this.setState({dialogOpen: false, dialogSlot: null, artistToSave: null, performanceArtists: []});
  }

  addArtistToPerformance(artist){
    if (artist == null) return;
    const performanceArtists = this.state.performanceArtists;
    performanceArtists.push(artist);
    this.setState({performanceArtists, artistToSave: null});
  }

  removeArtistFromPerformance(index){
    const performanceArtists = this.state.performanceArtists;
    performanceArtists.splice(index, 1);
    this.setState({performanceArtists});
  }

  changeDialogDuration = (e) => {
    const passedString = e.target.value;
    const value = passedString / 1;
    if (Number.isInteger(value) && value > 0){
      const dialogSlot = this.state.dialogSlot;
      dialogSlot.duration = value;
      dialogSlot.endTime = addMinutes(dialogSlot.startTime, value);
      this.setState({dialogSlot});
    }
  }

  savePerformance = () => {
    const startTime = this.state.dialogSlot.startTime;
    const endTime = this.state.dialogSlot.endTime;

    const artistIds = [];
    this.state.performanceArtists.forEach(x => artistIds.push(x.id));
    eventController.performances.add(this.event.id, artistIds, this.state.dialogSlot.stage.name, startTime, endTime)
      .then(() => this.fetchPerformances())
      .catch(e => console.log(e));
    this.closeDialog();
  }

  deletePerformance = (performance) => {
    eventController.performances.remove(this.event.id, performance.id)
      .then(() => this.fetchPerformances())
      .catch(e => console.log(e));
  }

  closeStageDialog = () => {
    this.setState({
      cardOpen: true,
      stageDialogOpen: false,
      stageDialogName: '',
      stageDialogIsEditing: false,
      stageDialogEditingStage: null
    });
  }

  handleOpenEditStageDialog = (stage) => {
    this.setState({
      stageDialogOpen: true,
      stageDialogName: stage.name,
      stageDialogIsEdit: true,
      stageDialogEditingStage: stage
    });
  }

  handleChangeStageName = e => {
    const stageDialogName = e.target.value;
    this.setState({stageDialogName});
  }

  saveNewStage = () => {
    const stageName = this.state.stageDialogName;
    if (stageName.length < 1) return;
    eventController.stages.add(this.event.id, stageName, null)
      .then(() => {
        this.fetchStages();
        this.closeStageDialog();
      });
  }

  saveExistingStage = () => {
    const oldStageName = this.state.stageDialogEditingStage.name;
    const newName = this.state.stageDialogName;

    if (!oldStageName.length > 0 || !newName.length > 0) return;

    eventController.stages.updateName(this.event.id, oldStageName, newName)
      .then(() => {
        this.fetchStages();
        this.closeStageDialog();
      })
  }

  deleteStage = () => {
    const stage = this.state.stageDialogEditingStage;
    if (!stage) return;
    let okToDelete = true;
    this.state.performances.forEach(performance => {
      if (performance.stage.name === stage.name)
        okToDelete = false;
    });
    if (!okToDelete){
      alert('This stage has performances and cannot be deleted. Please first remove all performances from this stage.');
      return;
    }
    eventController.stages.remove(this.event.id, stage.name)
      .then(() => {
        this.fetchStages();
        this.closeStageDialog();
      }).catch(e => console.log(e));
  }

  onStageImageUpdated = () => {
    this.closeStageDialog();
    this.fetchStages();
  }

  render() {
    const day = this.day;
    const maxWidth = Math.max(500, this.state.stages.length * 300 + 100)

    return (
      <>
        <CollapsibleCard title={format(day, 'P - EEEE')} sx={{marginBottom: 1, maxWidth: maxWidth}} defaultOpen={this.state.cardOpen}>
          <Divider />
          <CardContent>
            <Box style={{width: '100%'}}>
              <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Box style={timeStyle}><Typography>Time</Typography></Box>
                {this.state.stages.map((stage, index) =>
                  <Box key={index}
                       style={{
                         flex: 1,
                         textAlign: 'center',
                         display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                         backgroundImage: `url("${stage.img}")`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center',
                         minHeight: 96,
                         border: '1px solid purple'
                       }}>
                    <Box sx={{...performanceTextStyle, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                      <Typography variant='h4' color={'white'}>{stage.name}</Typography>
                      <Tooltip title={`Edit ${stage.name} Stage`}>
                        <IconButton onClick={() => this.handleOpenEditStageDialog(stage)}>
                          <Edit fontSize='small' sx={{color: 'white'}}/>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                )}
                <Box sx={{width: 40, backgroundColor: 'white'}}>
                  <Tooltip title='Add Stage'>
                    <IconButton aria-label='add-stage' onClick={() => this.setState({stageDialogOpen: true, stageDialogIsEdit: false})}>
                      <AddCircle color='purple' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            {this.state.rows.map((row, rowIndex) =>
              <Box key={rowIndex} style={rowStyle(row)}>
                <Box style={timeStyle}>{row.show && <Typography>{format(row.time, 'p')}</Typography>}</Box>
                {this.state.stages.map((stage, stageIndex) =>
                  <Box key={stageIndex} style={{flex: 1, borderRight: '1px solid lightGray'}}>
                    <SlotButton
                      time={row.time}
                      stage={stage}
                      openDialog={this.openDialog}
                      performances={this.state.performances}
                      deletePerformance={this.deletePerformance}
                      rowHeight={rowHeight}
                      interval={interval}
                    />
                  </Box>
                )}
                <Box sx={{width: 40, backgroundColor: 'white'}} />
              </Box>
            )}
            </Box>
          </CardContent>
        </CollapsibleCard>
        <Dialog open={this.state.dialogOpen} onClose={this.closeDialog}>
          <DialogTitle>Add Performance</DialogTitle>
          <DialogContent>
            {this.state.dialogSlot &&
                <>
                    <Typography>{this.state.dialogSlot.stage.name} - {format(this.state.dialogSlot.startTime, 'p')} to {format(this.state.dialogSlot.endTime, 'p')}</Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1}}>
                      <TextField
                        name='duration'
                        size='small'
                        type='number'
                        value={this.state.dialogSlot.duration}
                        onChange={this.changeDialogDuration}
                        sx={{width: 160, textAlign: 'right'}}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                        }}
                      />
                    </Box>
                  {this.state.lineup ?
                    <Autocomplete
                      id='artist-select'
                      size='small'
                      value={this.state.artistToSave}
                      options={this.state.lineup}
                      onChange={(event, newValue) => this.addArtistToPerformance(newValue)}
                      sx={{ width: 300, marginTop: 1 }}
                      renderInput={(params) => <TextField {...params} label="Add Artist" />}
                      getOptionLabel={(option) => option.name}
                    />
                    : null}

                  {this.state.performanceArtists.map((artist, index) =>
                    <Box key={index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <IconButton size='small' onClick={() => this.removeArtistFromPerformance(index)}>
                        <Clear />
                      </IconButton>
                      <Typography>{artist.name}</Typography>
                    </Box>
                  )}
                </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.savePerformance}>Save</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.stageDialogOpen} onClose={this.closeStageDialog}>
          <DialogTitle>{this.state.stageDialogIsEdit ? 'Edit Stage' :  'Add Stage'}</DialogTitle>
          <DialogContent>
            {
              this.state.stageDialogIsEdit && this.state.stageDialogEditingStage &&
                <StageImageUploader stage={this.state.stageDialogEditingStage} event={this.event} onStageImageUploaded={this.onStageImageUpdated} />
            }
            <TextField
              sx={{marginTop: 2}}
              name='stageName'
              value={this.state.stageDialogName}
              onChange={this.handleChangeStageName}
              label='Name'
              fullWidth
              />
          </DialogContent>
          <DialogActions>
            {
              this.state.stageDialogIsEdit ?
                <>
                  <Button onClick={this.saveExistingStage}>Update</Button>
                  <Button onClick={this.deleteStage}>Delete</Button>
                </>
                :
                <Button onClick={this.saveNewStage}>Create</Button>
            }
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const timeStyle = {textAlign: 'center', width: 100, borderRight: '1px solid lightGray'};
const rowStyle = (row) => {
  const style = {
    height: rowHeight,
    backgroundColor: row.color,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
  }
  if (row.show) style.borderTop = '1px solid lightGray';
  return style;
}

EventScheduleDay.propTypes = {
  event: PropTypes.object,
}

export default EventScheduleDay;
