import {NavLink} from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar, Box, Card, CircularProgress, Table, TableBody, TableCell, TableHead, TablePagination,
  TableRow, Typography
} from '@mui/material';
import getInitials from '../../utils/getInitials';
import { SocialLinks } from '../social/SocialLinks';
import Pagination from '../helpers/Pagination';

const FestivalListResults = ({ page, pageSize, pageNumber, handlePageSizeChange, handlePageNumberChange, loading, ...rest }) => {

  const handleLimitChange = (event) => {
    handlePageSizeChange(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    handlePageNumberChange(newPage);
  };

  if (loading){
    return <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
      <CircularProgress />
    </Box>
  }

  return (
    <Card {...rest}>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>PlurCount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.items.map((festival) => (
                <TableRow hover key={festival.id}>
                  <TableCell>
                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                      <Avatar src={festival.thumbnail} sx={{ mr: 2 }}>
                        {getInitials(festival.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <NavLink to={`/app/festivals/${festival.id}`} style={{width: '100%'}}>
                          {festival.name}
                        </NavLink>
                        <SocialLinks entity={festival} size={22} />
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {`${festival.city}, ${festival.state}, ${festival.country}`}
                  </TableCell>
                  <TableCell>
                    {festival.plurCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
    </Card>
  );
};

export default FestivalListResults;
