import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import {NavLink} from "react-router-dom";

function UserListToolbar ({filter, setFilter}) {
  return (
    <Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h2' color='primary'>Users</Typography>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button color="primary" variant="contained">
            Add user
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                helperText="Search"
                label="Search"
                name="search"
                onChange={(event) => setFilter(event.target.value)}
                value={filter}
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}


export default UserListToolbar;
