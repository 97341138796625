import React, {Component} from "react";
import {
  Avatar,
  Box, Button,
  Card, CardActions, CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import getInitials from "../../utils/getInitials";
import {NavLink} from "react-router-dom";
import {format} from "date-fns";
import {festivalController} from "../../controllers/festivalController";
import CollapsibleCard from "../helpers/CollapsibleCard";

class FestivalEventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        items: [],
        totalPages: 0,
        itemsFrom: 0,
        itemsTo: 0,
        totalItemsCount: 0
      },
      filter: '',
      pageSize: 15,
      pageNumber: 0,
      disconnected: false
    }
    this.id = props.id
    this.handleLimitChange.bind(this);
    this.handlePageChange.bind(this);
    this.fetchEvents.bind(this);
  }

  componentDidMount() {
    this.fetchEvents();
  }

  fetchEvents() {
    festivalController.events.get(this.id)
      .then(page => {
        this.setState({page, disconnected: false});
      })
      .catch(e => {
        this.setState({disconnected: true})
        console.log(e);
      });
  }

  handleLimitChange = e => {
    this.setState({limit: e.target.value});
    this.fetchEvents();
  }

  handlePageChange = (e, newPage) => {
    this.setState({pageNumber: newPage});
    this.fetchEvents();
  }

  render() {
    const page = this.state.page;

    return (
      <CollapsibleCard sx={{marginBottom: 1}} title='Events' defaultOpen={false}>
        <PerfectScrollbar>
          <Box sx={{minWidth: 800}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>PlurCount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {page.items.map((event) => (
                  <TableRow hover key={event.id}>
                    <TableCell>
                      <Box sx={{alignItems: 'center', display: 'flex'}}>
                        <Avatar src={event.img} sx={{ mr: 2 }}>
                          {getInitials(event.name)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          <NavLink to={`/app/events/${event.id}`} style={{width: '100%'}}>
                            {event.name}
                          </NavLink>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {
                        event.startDate && event.endDate ?
                          `${format(new Date(event.startDate), 'P')} - ${format(new Date(event.endDate), 'P')}` : ''
                      }
                    </TableCell>
                    <TableCell>
                      {event.plurCount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={page.totalItemsCount}
          onPageChange={this.handlePageChange}
          onRowsPerPageChange={this.handleLimitChange}
          page={this.state.pageNumber}
          rowsPerPage={this.state.pageSize}
          rowsPerPageOptions={[5, 15, 50]}
        />
        <CardActions>
          <NavLink to={'/app/events/new'}>
            <Button variant='contained' color='primary'>Create Event</Button>
          </NavLink>
        </CardActions>
      </CollapsibleCard>
    );
  }
}

export default FestivalEventList;
