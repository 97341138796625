import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button, CircularProgress,
  Container,
  TextField,
  Typography
} from '@mui/material';
import {api} from '../controllers/apiConfig';
import Logo from '../components/Logo';
import { useState } from 'react';
import {NavLink} from "react-router-dom";
import {Event, LibraryMusic} from "@mui/icons-material";

const Login = (props) => {
  const [loading, setLoading] = useState(false);

  const onLogin = (values) => {
    setLoading(true);
    const data = {email: values.email, password: values.password};
    const requestOptions ={
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    fetch(`${api}/account/login`, requestOptions)
      .then(response => response.json())
      .then(user => {
        setLoading(false);
        props.updateUser(user);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  }

  const linkStyle = {
    marginTop: 2,
    marginBottom: 2,
    textAlign: 'center'
  }

  const buttonStyle = {
    margin: 1,
    flex: 1
  }

  return (
    <>
      <Helmet>
        <title>Login | Bass Gremlin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => onLogin(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Logo />
                  <Typography color="textPrimary" variant="h2">
                    Admin Login
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  disabled={loading}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  disabled={loading}
                />
                <Box sx={{ py: 2 }}>
                  {
                    loading ?
                      <Box style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress />
                      </Box> :
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Sign in
                      </Button>
                  }
                </Box>
              </form>
            )}
          </Formik>
        </Container>
        <Container maxWidth="sm" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Typography variant='h4' sx={linkStyle}>Are you an Artist or Event Organizer who wants to publish their information on Bass Gremlin?</Typography>
          <Button sx={buttonStyle} color='secondary' startIcon={<LibraryMusic />} variant='contained'>Request Artist Profile Access</Button>
          <Button sx={buttonStyle} color='secondary' startIcon={<Event />} variant='contained'>Request Event/Festival Profile Access</Button>
        </Container>
      </Box>
    </>
  );
};

export default Login;
