import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import {Box, Container, Typography} from '@mui/material';
import FestivalListResults from '../components/festival/FestivalListResults';
import FestivalListToolbar from '../components/festival/FestivalListToolbar';
import {festivalController} from "../controllers/festivalController";

class FestivalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        items: [],
        totalPages: 0,
        itemsFrom: 0,
        itemsTo: 0,
        totalItemsCount: 0
      },
      filter: '',
      pageSize: 15,
      pageNumber: 0,
      disconnected: false,
      loading: false,
    }
    this.fetchFestivals.bind(this);
  }

  componentDidMount() {
    this.fetchFestivals(this.state.filter, this.state.pageNumber, this.state.pageSize);
  }

  fetchFestivals (filter, pageNumber, pageSize) {
    this.setState({loading: true});
    festivalController.getAll(filter, pageNumber, pageSize)
      .then(page => this.setState({page, disconnected: false, loading: false}))
      .catch(e => {
        this.setState({disconnected: true, loading: false})
        console.log(e);
      });
  }

  setFilter = (filter) => {
    this.setState({filter});
    this.setState({pageNumber: 0});
    this.fetchFestivals(filter, this.state.pageNumber, this.state.pageSize);
  }

  handlePageSizeChange = (pageSize) => {
    this.setState({pageSize});
    this.fetchFestivals(this.state.filter, this.state.pageNumber, pageSize);
  }

  handlePageNumberChange = (pageNumber) => {
    this.setState({pageNumber});
    this.fetchFestivals(this.state.filter, pageNumber, this.state.pageSize);
  }

  render(){
    return (
      <>
        <Helmet>
          <title>Festivals | Fest Champs</title>
        </Helmet>
        <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
          <Container maxWidth={false}>
            <FestivalListToolbar filter={this.state.filter} setFilter={this.setFilter} />
            <Box sx={{ pt: 3 }}>
              {
                this.state.disconnected ?
                  <Typography>Failed to connect to the server.</Typography>
                  :
                  <>
                    {
                      this.state.page && this.state.page.items ?
                        <FestivalListResults
                          page={this.state.page}
                          handlePageNumberChange={this.handlePageNumberChange}
                          handlePageSizeChange={this.handlePageSizeChange}
                          pageSize={this.state.pageSize}
                          pageNumber={this.state.pageNumber}
                          loading={this.state.loading}
                        />
                        : null
                    }
                  </>
              }
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

export default FestivalList;
