import React, {useRef, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Dialog, DialogTitle, DialogActions, DialogContent, Table, TableHead, TableRow, TableCell, TableBody, Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { csvToObjectArray } from '../../utils/csvConverter';

function EventListToolbar (props) {
  let navigate = useNavigate();
  const [upload, setUpload] = useState([]);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const fileImportRef = useRef();
  const reader = new FileReader();
  const setFilter = props.setFilter;
  const filter = props.filter;

  const handleImportRefChange = e => {
    const file = e.target.files[0];
    reader.onload = function(event) {
      const data = csvToObjectArray(event.target.result);
      setUpload(data);
      setShowImportDialog(true);
    };
    reader.readAsText(file);
  }

  const handleSaveImport = () => {
    if (!upload)
      setShowImportDialog(false);
  }

  return (
    <Box {...props}>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h2' color='primary'>Events</Typography>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={() => fileImportRef.current?.click()}>Import</Button>
          <input onChange={handleImportRefChange} multiple={false} ref={fileImportRef} type='file' accept='.csv' hidden/>
          <Button sx={{ mx: 1 }}>Export</Button>
          <Button color="primary" variant="contained" onClick={() => navigate('/app/events/new')}>
            Add event
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                helperText="Search"
                label="Search"
                name="search"
                onChange={(event) => setFilter(event.target.value)}
                value={filter}
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={showImportDialog}>
        <DialogTitle>
          Validate Event Import
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  id
                </TableCell>
                <TableCell>
                  name
                </TableCell>
                <TableCell>
                  location
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upload.map((event) =>
                <TableRow key={event.id}>
                  <TableCell>
                    {event.id}
                  </TableCell>
                  <TableCell>
                    {event.name}
                  </TableCell>
                  <TableCell>
                    {event.location}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setShowImportDialog(false)}}>
            Cancel
          </Button>
          <Button onClick={handleSaveImport}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}


export default EventListToolbar;
