import {
  Box,
  Container
} from '@mui/material';
import {useParams} from "react-router-dom";
import PerformanceDetails from '../components/performance/PerformanceDetails';

const PerformancePage = () => {
  let { id, event } = useParams()

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <PerformanceDetails id={id} event={event}/>
        </Container>
      </Box>
    </>
  );

};

export default PerformancePage;
