import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import {Box, Container, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

class ArtistListForCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artists: [],
    }
  }

  componentDidMount () {

  }

  render(){

    return (
      <>
        <Helmet>
          <title>Artists | Fest Champs</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
          <Container maxWidth={false}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>About</TableCell>
                  <TableCell>Genre</TableCell>
                  <TableCell>Img</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.artists.map((artist,index) =>
                  <TableRow key={index}>
                    <TableCell>{artist.name}</TableCell>
                    <TableCell>{artist.about}</TableCell>
                    <TableCell>{artist.genre}</TableCell>
                    <TableCell>{artist.img}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Container>
        </Box>
      </>
    );
  }
}

export default ArtistListForCSV;
