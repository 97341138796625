import {api} from './apiConfig'; //export const api = 'https://localhost:7200/api';
import { auth } from '../auth'; //getUser: () => {return JSON.parse(sessionStorage.getItem('user'));}

const pageSizeOptions = [5,15,50];

export const artistController = {
  getAll: (filter, pageNumber, pageSize) => {
    return new Promise((resolve, reject) => {
      if (!pageSizeOptions.find(x => x===pageSize)) reject('Invalid page size.');
      if (pageNumber < 0) reject('Invalid page number.');
      const search = filter.length > 0 ? `search=${filter}&` : '';
      const apiURL = `${api}/artists?${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      fetch(apiURL)
        .then(response => response.json())
        .then(page => {
          resolve(page);
        })
        .catch(e => {
          reject('Internal Server Error');
          console.log(e);
        });
    })
  },
  get: (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${api}/artists/${id}`).then(response => response.json())
        .then(artist => resolve(artist))
        .catch(e => {
          console.log(e);
          reject('Failed to load artist. Server Error.');
        });
    })
  },
  getNew: () => {
    return {
      id: 'new',
      name: '',
      about: '',
      genres: [],
      img: '',
      plurCount: 0,
      spotify: '',
      appleMusic: '',
      soundCloud: '',
      youTubeMusic: '',
      facebook: '',
      instagram: '',
      twitter: '',
      youTube: '',
      website: '',
      tikTok: ''
    }
  },
  add: (artist) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const data = {name: artist.name, about: artist.about };
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(data)
      }
      fetch(`${api}/artists`, requestOptions)
        .then(() => {
          const newId = artist.name.toLowerCase().replaceAll(' ','-');
          artist.id = newId;
          resolve(artist);
        })
        .catch(e => {
          console.log(e);
          reject('Failed to create artist. Server Error');
        });
    })
  },
  import: (list) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(list)
      }
      fetch(`${api}/artists/import`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to create artist. Server Error');
        });
    })
  },
  update: (artist) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(artist)
      }
      fetch(`${api}/artists/${artist.id}`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to update artist. Server Error');
        });
    })
  },
  delete: (artist) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(`${api}/artists/${artist.id}`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to Delete. Server Error.');
        });
    });
  },
  updateImage: (id, imageData) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      console.log(imageData);
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(imageData)
      }

      fetch(`${api}/artists/${id}/image`, requestOptions).then((response) => {
        resolve('Success');
      }).catch(e => {
        console.log(e);
        reject('Failed to save Image');
      });
    })
  },
  genres: {
    getAll: () => {
      return new Promise((resolve, reject) => {
        fetch(`${api}/artists/genres`).then(response => response.json())
          .then(allGenres => resolve(allGenres))
          .catch(e => {
            console.log(e);
            reject('Server Error');
          });
      });
    },
    add: (id, genre) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');

        const requestOptions = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }

        fetch(`${api}/artists/${id}/addGenre/${genre}`, requestOptions)
          .then(() => {resolve('Added');})
          .catch(e => {
            console.log(e);
            reject('Server Error');
          });
      })
    },
    remove: (id, genre) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');

        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }

        fetch(`${api}/artists/${id}/removeGenre/${genre}`, requestOptions)
          .then(() => {resolve('Removed');})
          .catch(e => {
            console.log(e);
            reject('Server Error');
          });
      })
    }
  },
  events: {
    getAll: (id) => {
      return new Promise((resolve, reject) => {
        fetch(`${api}/artists/${id}/events`).then(response => response.json())
          .then(page => resolve(page.items))
          .catch(e => {
            console.log(e);
            reject('Failed to load events. Server Error.');
          });
      })
    }
  },
  tags: {
    get: (id) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'GET',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/artists/${id}/tags`, requestOptions)
          .then(response => response.json())
          .then(result => resolve(result))
          .catch(e => {
            console.log(e);
            reject('Failed to get tags. Server Error.');
          });
      })
    },
    add: (id, name) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/artists/${id}/addTag/${name}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
    remove: (id, name) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/artists/${id}/removeTag/${name}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
  }
}
