import React, {useRef, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Typography,
} from '@mui/material';
import {NavLink} from "react-router-dom";
import { csvToObjectArray } from '../../utils/csvConverter';
import {artistController} from "../../controllers/artistController";

function ArtistListToolbar (props) {
  const [artistUpload, setArtistUpload] = useState([]);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const fileImportRef = useRef();
  const reader = new FileReader();
  const setFilter = props.setFilter;
  const filter = props.filter;

  const handleImportRefChange = e => {
    const file = e.target.files[0];
    reader.onload = function(event) {
      const data = csvToObjectArray(event.target.result);
      setArtistUpload(data);
      setShowImportDialog(true);
    };
    reader.readAsText(file);
  }

  const handleSaveImport = () => {
    artistController.import(artistUpload)
      .then(() => setFilter(''))
      .catch(e => console.error(e));
  }

  return (
    <Box {...props}>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h2' color='primary'>Artists</Typography>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={() => fileImportRef.current.click()}>
            Import
          </Button>
          <input onChange={handleImportRefChange} multiple={false} ref={fileImportRef} type='file' accept='.csv' hidden/>
          <Button sx={{ mx: 1 }}>
            Export
          </Button>
          <NavLink to={`/app/artists/new`}>
            <Button color="primary" variant="contained">
              Add artist
            </Button>
          </NavLink>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                helperText="Search"
                label="Search"
                name="search"
                onChange={(event) => setFilter(event.target.value)}
                value={filter}
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={showImportDialog}>
        <DialogTitle>
          Validate Artist Import
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>name</TableCell>
                <TableCell>about</TableCell>
                <TableCell>genres</TableCell>
                <TableCell>spotify</TableCell>
                <TableCell>appleMusic</TableCell>
                <TableCell>youTubeMusic</TableCell>
                <TableCell>soundCloud</TableCell>
                <TableCell>instagram</TableCell>
                <TableCell>facebook</TableCell>
                <TableCell>twitter</TableCell>
                <TableCell>website</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {artistUpload.map((artist) =>
                <TableRow key={artist.name}>
                  <TableCell>{artist.name}</TableCell>
                  <TableCell>{artist.about}</TableCell>
                  <TableCell>{artist.genres}</TableCell>
                  <TableCell>{artist.spotify}</TableCell>
                  <TableCell>{artist.appleMusic}</TableCell>
                  <TableCell>{artist.youTubeMusic}</TableCell>
                  <TableCell>{artist.soundCloud}</TableCell>
                  <TableCell>{artist.instagram}</TableCell>
                  <TableCell>{artist.facebook}</TableCell>
                  <TableCell>{artist.twitter}</TableCell>
                  <TableCell>{artist.website}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setShowImportDialog(false)}}>
            Cancel
          </Button>
          <Button onClick={handleSaveImport}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}


export default ArtistListToolbar;
