export function objectArrayToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr)

  return array.map(it => {
    return Object.values(it).toString()
  }).join('\n')
}

export function csvToObjectArray(str, delimiter = ",") {
  const headers = str.slice(0, str.indexOf("\n") - 1).split(delimiter);
  const rows = str.slice(str.indexOf("\n") + 1).split("\r\n");

  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  return arr;
}
