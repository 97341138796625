import React, {Component, createRef} from "react";
import {
  Avatar,
  Box,
  Button,
  Card, CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid, Snackbar,
  TextField, Typography
} from '@mui/material';
import getInitials from "../../utils/getInitials";
import {Helmet} from "react-helmet";
import FestivalEventList from "./FestivalEventList";
import { auth } from '../../auth';
import {festivalController} from "../../controllers/festivalController";
import TagBox from '../tags/TagBox';
import PostList from '../post/PostList';
import NotFound from '../helpers/NotFound';
import CollapsibleCard from "../helpers/CollapsibleCard";

class FestivalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      festival: null,
      uploadImage: null,
      previewImage: '',
      snackbarOpen: false,
      snackbarMessage: 'Festival Saved',
      notFound: false,
    }
    this.inputReference = createRef();
    this.user = auth.getUser();
    this.handleImageChange.bind(this);
    this.handleImageUpload.bind(this);
    this.handleNameChange.bind(this);
    this.handleLocationValueChanged.bind(this);
    this.handleAboutChange.bind(this);
    this.handleSaveDetails.bind(this);
    this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (this.state.id === 'new'){
      this.setState({festival: festivalController.getNew()});
      return;
    }

    festivalController.get(this.state.id)
      .then(festival => {
        if (festival.status === 404){
          this.setState({festival: null, notFound: true});
          return;
        }
        this.setState({ festival });
      })
      .catch(e => console.log(e));
  }

  handleImageChange = e => {
    if (e.target.files[0]){
      const image = e.target.files[0];
      this.setState({
        uploadImage: image,
        previewImage: URL.createObjectURL(image),
      })
    }
  }

  handleImageUpload = () => {
    const image = this.state.uploadImage;

    if (image){
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(image);
    }
  }

  sendImageRequest = (imageData) => {
    festivalController.updateImage(this.state.id, imageData).then(() => {
      this.setState({snackbarMessage: 'Image Uploaded Successfully', snackbarOpen: true})
    }).catch(() => {
      this.setState({snackbarMessage: 'Image Failed to Save', snackbarOpen: true})
    });
  }

  handleNameChange = e => {
    let festival = this.state.festival;
    festival.name = e.target.value;
    this.setState({festival});
  }

  handleLocationValueChanged = e => {
    let festival = this.state.festival;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    festival[name] = value;
    this.setState({festival});
  }

  handleAboutChange = e => {
    let festival = this.state.festival;
    festival.about = e.target.value;
    this.setState({festival});
  }

  handleInputChange = e => {
    let festival = this.state.festival;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    festival[name] = value;
    this.setState({festival});
  }

  handleSaveDetails = () => {
    const festival = this.state.festival;
    if (festival.name === null || festival.name === ''){
      alert('Name is a required field.')
      return;
    }

    if (festival.id === 'new'){
      festivalController.add(festival)
        .then(newFestival => {
          this.setState({id: newFestival.id, festival: newFestival, snackbarMessage: 'Festival Details Saved!', snackbarOpen: true});
        })
        .catch(e => console.log(e));
    } else {
      festivalController.update(festival).then(() => {
        this.setState({snackbarMessage: 'Festival Details Saved!', snackbarOpen: true});
      }).catch(e => console.log(e));
    }
  }

  handleDelete =() => {
    alert('TODO: Delete');
  }

  render() {
    if (this.state.notFound){
      return (
        <NotFound entityType='Festival' />
      );
    }

    const festival = this.state.festival;

    if (this.state.festival){
      return (
        <>
          <Helmet>
            <title>{festival.name} | Bass Gremlin</title>
          </Helmet>
          {this.state.id === 'new' ? null :
            <Card sx={{ marginBottom: 1 }}>
              <CardContent>
                <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Typography variant='h2'>{festival.name}</Typography>
                    <Avatar
                      sx={{ width: 240, height: 240, margin: 2, fontSize: 'xxx-large' }}
                      src={this.state.previewImage ? this.state.previewImage : this.state.festival.img}
                    >
                      {getInitials(festival.name)}
                    </Avatar>
                  </Box>
                </Box>
              </CardContent>
              <CardActions disableSpacing>
                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
                  {this.state.previewImage ?
                    <Button variant="contained" onClick={this.handleImageUpload}>
                      Save Image
                    </Button>
                    :
                    <Button color="primary" variant="contained" onClick={() => this.inputReference.current?.click()}>
                      Change Image
                    </Button>}
                  <input
                    type='file'
                    multiple={false}
                    accept='.jpg,.jpeg'
                    hidden
                    ref={this.inputReference}
                    onChange={this.handleImageChange} />
                </Box>
              </CardActions>
            </Card>
          }
          <form autoComplete="off" noValidate>
            <Card sx={{marginBottom: 1}}>
              <CardHeader title="Festival Profile" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <MediumTextInput
                    name='name'
                    label='Festival Name'
                    onChange={this.handleNameChange}
                    value={festival.name}
                  />
                  <MediumTextInput
                    name='country'
                    label='Country'
                    onChange={this.handleLocationValueChanged}
                    value={festival.country}
                  />
                  <MediumTextInput
                    name='city'
                    label='City'
                    onChange={this.handleLocationValueChanged}
                    value={festival.city}
                  />
                  <MediumTextInput
                    name='state'
                    label='State'
                    onChange={this.handleLocationValueChanged}
                    value={festival.state}
                  />
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={7}
                      helperText="About"
                      label="About"
                      name="about"
                      onChange={this.handleAboutChange}
                      required
                      value={festival.about}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                <Button color="primary" variant="contained" onClick={this.handleSaveDetails}>
                  Save details
                </Button>
              </Box>
            </Card>
          </form>
          {festival.id === 'new' ? null :
            <>
              <CollapsibleCard title='Links' defaultOpen={false} sx={{marginBottom: 1}}>
                <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, padding: 2}}>
                  <TextField value={festival.facebook ? festival.facebook : ''} onChange={this.handleInputChange} size="small" name="facebook" label="Facebook Link" sx={{marginBottom: 2}}/>
                  <TextField value={festival.instagram ? festival.instagram : ''} onChange={this.handleInputChange} size="small" name="instagram" label="Instagram Link" sx={{marginBottom: 2}}/>
                  <TextField value={festival.twitter ? festival.twitter : ''} onChange={this.handleInputChange} size="small" name="twitter" label="Twitter Link" sx={{marginBottom: 2}}/>
                  <TextField value={festival.website ? festival.website : ''} onChange={this.handleInputChange} size="small" name="website" label="Website Link" sx={{marginBottom: 2}}/>
                </Box>
              </CollapsibleCard>
              <TagBox id={this.state.id} type='festival' />
              <FestivalEventList id={this.state.id} />
              <PostList id={this.state.id} type='festivals' />
            </>
          }
          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={this.state.snackbarOpen}
            autoHideDuration={3000}
            onClose={() => this.setState({snackbarOpen: false})}
            message={this.state.snackbarMessage}
          />
        </>
      );
    } else{
      return <></>
    }
  }

  _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.sendImageRequest(btoa(binaryString));
  }
}

const MediumTextInput = ({name, label, onChange, value}) => {
  return (
    <Grid item md={6} xs={12}>
      <TextField
        fullWidth
        label={label}
        name={name}
        onChange={onChange}
        required
        value={value}
        variant="outlined"
      />
    </Grid>
  );
}

export default FestivalDetails;
