import {
  Box,
  Container
} from '@mui/material';
import {useParams} from "react-router-dom";
import FestivalDetails from "../components/festival/FestivalDetails";

const FestivalPage = () => {
  let { id } = useParams()

  return (
    <>
      <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
        <Container maxWidth="lg">
          <FestivalDetails id={id}/>
        </Container>
      </Box>
    </>
  );

};

export default FestivalPage;
