import React from 'react';
import { TablePagination } from '@mui/material';

const Pagination = ({page, handlePageChange, handleLimitChange, pageNumber, pageSize}) => {
  return (
    <TablePagination
      component="div"
      count={page.totalItemsCount}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={pageNumber}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[5, 15, 50]}
    />
  );
}

export default Pagination;
