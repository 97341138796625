import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import LoginCheck from './LoginCheck';

const App = () => {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <LoginCheck/>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
