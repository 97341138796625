import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@mui/material';
import EventListResults from '../components/event/EventListResults';
import EventListToolbar from '../components/event/EventListToolbar';
import {eventController} from "../controllers/eventController";

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        items: [],
        totalPages: 0,
        itemsFrom: 0,
        itemsTo: 0,
        totalItemsCount: 0
      },
      filter: '',
      pageSize: 15,
      pageNumber: 0,
      disconnected: false,
      loading: false,
    }
    this.fetchEvents.bind(this);
    this.setFilter.bind(this);
    this.handlePageSizeChange.bind(this);
    this.handlePageNumberChange.bind(this);
  }

  componentDidMount() {
    this.fetchEvents(this.state.filter, this.state.pageNumber, this.state.pageSize);
  }

  fetchEvents (filter, pageNumber, pageSize) {
    this.setState({loading: true});
    eventController.getAll(filter, pageNumber, pageSize)
      .then(page => this.setState({page, disconnected: false, loading: false}))
      .catch(e => {
        this.setState({disconnected: true, loading: false})
        console.log(e);
      });
  }

  setFilter = (filter) => {
    this.setState({filter});
    this.setState({pageNumber: 0});
    this.fetchEvents(filter, this.state.pageNumber, this.state.pageSize);
  }

  handlePageSizeChange = (pageSize) => {
    this.setState({pageSize});
    this.fetchEvents(this.state.filter, this.state.pageNumber, pageSize);
  }

  handlePageNumberChange = (pageNumber) => {
    this.setState({pageNumber});
    this.fetchEvents(this.state.filter, pageNumber, this.state.pageSize);
  }

  render(){
    const filter = this.state.filter;

    return (
      <>
        <Helmet>
          <title>Events | Bass Gremlin</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
          <Container maxWidth={false}>
            <EventListToolbar filter={filter} setFilter={this.setFilter}/>
            <Box sx={{ pt: 3 }}>
              {
                this.state.disconnected ?
                  <Typography>Failed to connect to the server.</Typography>
                  :
                  <>
                    {
                      this.state.page && this.state.page.items ?
                        <EventListResults
                          page={this.state.page}
                          handlePageNumberChange={this.handlePageNumberChange}
                          handlePageSizeChange={this.handlePageSizeChange}
                          pageSize={this.state.pageSize}
                          pageNumber={this.state.pageNumber}
                          loading={this.state.loading}
                        />
                        : null
                    }
                  </>
              }
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

export default EventList;
