import React, {Component} from "react";
import {Avatar, Card, CardContent, Grid, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {Calendar} from "react-feather";
import {NavLink} from "react-router-dom";
import {eventController} from "../../controllers/eventController";

class TotalEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    }
  }

  componentDidMount() {
    eventController.getAll('',0,5)
      .then(page => this.setState({count: page.totalItemsCount}))
      .catch(e => console.log(e));
  }

  render(){
    return (
      <Card>
        <CardContent>
          <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6">
                TOTAL EVENTS
              </Typography>
              <NavLink  to={`/app/events`}>
                <Typography color="textPrimary" variant="h3">
                  {this.state.count}
                </Typography>
              </NavLink>
            </Grid>
            <Grid item>
              <Avatar sx={{backgroundColor: blue[600], height: 56, width: 56}}>
                <Calendar />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default TotalEvents;
