import React, {useState} from "react";
import {
  Avatar,
  Box,
  Card, CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import getInitials from "../../utils/getInitials";
import {NavLink} from "react-router-dom";
import {format} from "date-fns";
import CollapsibleCard from "../helpers/CollapsibleCard";

const ArtistEventList = (props) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const events = props.events;


  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <CollapsibleCard sx={{marginTop: 1, marginBottom: 1}} defaultOpen={false} title='Events'>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Dates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.slice(page * limit, limit + page * limit).map((event) => (
                <TableRow hover key={event.id}>
                  <TableCell>
                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                      <Avatar src={event.thumbnail} sx={{ mr: 2 }}>
                        {getInitials(event.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <NavLink to={`/app/events/${event.id}`} style={{width: '100%'}}>
                          {event.name}
                        </NavLink>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {`${event.city}, ${event.state}, ${event.country}`}
                  </TableCell>
                  <TableCell>
                    {
                      event.startDate && event.endDate ?
                        `${format(new Date(event.startDate),'P')} - ${format(new Date(event.endDate),'P')}` : ''
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={events.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </CollapsibleCard>
  );
}

export default ArtistEventList;
