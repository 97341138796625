import React, {Component} from 'react';
import { eventController } from '../../../controllers/eventController';
import NotFound from '../../helpers/NotFound';
import { addDays, differenceInDays, format } from 'date-fns';
import {Avatar, Box, Card, CardContent, CardHeader, colors, Container, Typography} from '@mui/material';
import theme from '../../../theme';
import EventScheduleDay from "./EventScheduleDay";
import { Helmet } from 'react-helmet';

class EventScheduleScaffold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      days: [],
      notFound: false,
    }
    this.id = props.id;
  }

  componentDidMount() {
    this.fetchEvent();
  }

  fetchEvent () {
    eventController.get(this.id)
      .then(event => {
        if (event.status === 404){
          this.setState({event: null, notFound: true});
          return;
        }
        const dayCount = differenceInDays(new Date(event.endDate), new Date(event.startDate));
        const days = [];
        for (let i = 0; i <= dayCount; i++) {
          days.push(addDays(new Date(event.startDate), i));
        }
        this.setState({ event, days, notFound: false });
      })
      .catch(e => {
        this.setState({notFound: true});
        console.log(e);
      });
  }

  render() {
    const event = this.state.event;

    if (this.state.notFound){
      return <NotFound entityType='Event Schedule' />
    }

    if (!event)
      return null;

    return (
      <Container>
        <Helmet>
          <title>{this.state.event.name} | Bass Gremlin</title>
        </Helmet>
        <Typography variant='h2' sx={{marginBottom: 2}}>Event Scheduler</Typography>
        {this.state.days.map((day, index) =>
          <EventScheduleDay key={index} day={day} event={event} />
        )}
      </Container>
    )
  }
}

export default EventScheduleScaffold;
