import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, Snackbar,
  TextField
} from '@mui/material';
import { auth } from '../../auth';
import {accountController} from "../../controllers/accountController";

const AccountProfileDetails = () => {
  const [user, setUser] = useState(auth.getUser());
  const [values, setValues] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    username: user.username,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSaveDetails = () => {
    if (values.firstName.length <= 0 || values.lastName.length <= 0){
      alert('First and Last name are required.');
      return;
    }
    accountController.update(values).then(() => {
      setUser(auth.getUser());
      setSnackbarMessage('Account Updated');
      setSnackbarOpen(true);
    }).catch(() => {
      setSnackbarMessage('Failed to update.');
      setSnackbarOpen(true);
    })
  }

  return (
    <form autoComplete="off">
      <Card>
        <CardHeader title="Profile"/>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
          <Button color="primary" variant="contained" onClick={() => handleSaveDetails()}>
            Save details
          </Button>
        </Box>
      </Card>
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </form>
  );
};

export default AccountProfileDetails;
