import React, {Component} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import {Helmet} from "react-helmet";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {validatePerformance} from "../../utils/formSubmitValidators";

class PerformanceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      eventId: props.event,
      event: {},
      performance: null,
      artists: [],
      artistNames: [],
      stages: [],
      stageNames: []
    }
    this.handleSaveDetails.bind(this);
    this.handlePerformanceChange.bind(this);
    this.handleArtistChange.bind(this);
    this.handleStageChange.bind(this);
    this.handleDateChange.bind(this);
    this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange.bind(this);
  }

  componentDidMount() {
    //Setup default values for new performance
    if (this.state.id === 'new'){
      const performance = {
        id: 'new',
        event: this.state.eventId,
        stage: '',
        artist: '',
        date: '',
        startTime: '',
        endTime: '',
        plurCount: 0
      }
      this.setState({performance});
    }
  }

  handlePerformanceChange = e => {
    let performance = this.state.performance;
    performance[e.target.name] = e.target.value;
    this.setState({performance});
  }

  handleArtistChange = (e, newValue) => {
    let performance = this.state.performance;
    performance.artist = newValue;
    this.setState({performance});
  }

  handleStageChange = (e, newValue) => {
    let performance = this.state.performance;
    performance.stage = newValue;
    this.setState({performance});
  }

  handleDateChange = newValue => {
    let performance = this.state.performance;
    performance.date = newValue;
    this.setState({performance});
  }

  handleStartTimeChange = newValue => {
    let performance = this.state.performance;
    performance.startTime = newValue;
    this.setState({performance});
  }

  handleEndTimeChange = newValue => {
    let performance = this.state.performance;
    performance.endTime = newValue;
    this.setState({performance});
  }

  handleSaveDetails = () => {
    const performance = this.state.performance;
    const valid = validatePerformance(performance);
    if (valid.valid === false)
      alert(valid.error);
  }

  render() {
    const artistNames = this.state.artistNames;
    const stageNames = this.state.stageNames;
    const event = this.state.event;
    const performance = this.state.performance;

    if (this.state.performance){
      return (
        <>
          <Helmet>
            <title>{event.name} | Fest Champs</title>
          </Helmet>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form
              autoComplete="off"
              noValidate
            >
              <Card sx={{marginBottom: 1}}>
                <CardHeader
                  title="Performance Profile"
                  subheader={event.name}
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        fullWidth
                        id="artist"
                        sx={{ width: 300 }}
                        options={artistNames}
                        autoHighlight
                        value={artistNames.find(a => a.id === performance.artist)}
                        onChange={(event, newValue) => {
                          let performance = this.state.performance;
                          performance.artist = newValue.id;
                          this.setState({performance})
                        }}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Artist"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        fullWidth
                        id="stage"
                        sx={{ width: 300 }}
                        options={stageNames}
                        value={stageNames.find(a => a.id === performance.stage)}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          let performance = this.state.performance;
                          performance.stage = newValue.id;
                          this.setState({performance})
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Stage"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} xs={8}>
                      <DatePicker
                        label="Date"
                        onChange={(newValue) => this.handleDateChange(newValue)}
                        value={performance.date}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item md={4} xs={8}>
                      <TimePicker
                        label="Start Time"
                        value={performance.startTime}
                        onChange={(newValue) => this.handleStartTimeChange(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item md={4} xs={8}>
                      <TimePicker
                        label="End Time"
                        value={performance.endTime}
                        onChange={(newValue) => this.handleEndTimeChange(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleSaveDetails}
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
          </LocalizationProvider>
        </>
      );
    } else{
      return <></>
    }
  }
}

export default PerformanceDetails;
