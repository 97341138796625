export const validatePerformance = performance => {
  if (!performance){return {valid: false, error: 'undefined'}}
  if (!performance.event){return {valid: false, error: 'Event is missing'}}
  if (!performance.stage){return {valid: false, error: 'Stage is missing'}}
  if (!performance.artist){return {valid: false, error: 'Artist is missing'}}
  if (!performance.startTime){return {valid: false, error: 'Start Time is missing'}}
  if (!performance.endTime){return {valid: false, error: 'End Time is missing'}}
  if (!performance.date){return {valid: false, error: 'Date is missing'}}
  if (performance.startTime > performance.endTime){return {valid: false, error: 'End Time cannot be before Start Time'}}
  return {valid: true, error: ''}
}

export const validateStage = stage => {
  if (!stage){return {valid: false, error: 'undefined'}}
  if (!stage.event){return {valid: false, error: 'Event is missing'}}
  if (!stage.name){return {valid: false, error: 'Name is missing'}}
  return {valid: true, error: ''}
}

export const validateArtist = artist => {
  if (!artist){return {valid: false, error: 'undefined'}}
  if (!artist.name){return {valid: false, error: 'Name is missing'}}
  return {valid: true, error: ''}
}
