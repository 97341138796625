import React, {Component, createRef, useRef, useState} from "react";
import {Box, CircularProgress, IconButton} from "@mui/material";
import {eventController} from "../../../controllers/eventController";
import {FileUpload, Save} from "@mui/icons-material";

class StageImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadImage: null,
      previewImage: null,
      isLoading: false,
    }
    this.imgRef = createRef();
    this.stage = props.stage;
    this.event = props.event;
    this.onStageImageUploaded = props.onStageImageUploaded;
  }

  handleImageChange = e => {
    if (e.target.files[0]){
      const image = e.target.files[0];
      this.setState({
        uploadImage: image,
        previewImage: URL.createObjectURL(image)
      })
    }
  }

  handleImageUpload = async () => {
    const image = this.state.uploadImage;
    if (image){
      this.setState({isLoading: true});
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(image);
    }
  }

  sendImageRequest = (imageData) => {
    eventController.stages.updateImage(this.event.id, this.stage.name, imageData)
      .then(() => {
        this.setState({isLoading: false, previewImage: null});
        this.onStageImageUploaded();
      })
      .catch(() => alert('Failed to save image'));
  }

  _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.sendImageRequest(btoa(binaryString));
  }

  render() {
    return (
      <Box sx={{
        width: 300, height: 300,
        backgroundImage: `url(${this.state.previewImage ? this.state.previewImage : this.stage.img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'lightgray',
        padding: 1,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end'
      }}>
        <Box sx={{backgroundColor: 'black', width: 40, height: 40, borderRadius: 2}}>
          {
            this.state.previewImage ?
              <>
                {this.state.isLoading ?
                  <CircularProgress sx={{color: 'white'}} />
                  :
                  <IconButton onClick={this.handleImageUpload}>
                    <Save sx={{color: 'white'}} />
                  </IconButton>
                }
              </>
              :
              <IconButton onClick={() => this.imgRef.current.click()}>
                <FileUpload sx={{color: 'white'}} />
              </IconButton>
          }
        </Box>
        <input
          type='file'
          multiple={false}
          accept='.jpg,.jpeg'
          hidden
          ref={this.imgRef}
          onChange={this.handleImageChange} />
      </Box>
    )
  }
}

export default StageImageUploader;
