import React, {Component, createRef} from "react";
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  Dialog, DialogContent, DialogTitle,
  Divider,
  Grid, IconButton, List, ListItem, Snackbar, Switch,
  TextField, Typography
} from '@mui/material';
import getInitials from "../../utils/getInitials";
import {Helmet} from "react-helmet";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { NavLink } from 'react-router-dom';
import EventLineup from "./EventLineup";
import PerformanceList from "../performance/PerformanceList";
import {eventController} from "../../controllers/eventController";
import {festivalController} from "../../controllers/festivalController";
import TagBox from '../tags/TagBox';
import PostList from '../post/PostList';
import { Clear } from '@mui/icons-material';
import NotFound from '../helpers/NotFound';
import CollapsibleCard from '../helpers/CollapsibleCard';

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      event: null,
      uploadImage: null,
      previewImage: '',
      uploadPoster: null,
      previewPoster: '',
      festivals: [],
      snackbarOpen: false,
      snackbarMessage: 'Event Saved',
      dialogOpen: false,
      notFound: false,
    }
    this.inputReference = createRef();
    this.posterRef = createRef();
    this.fetchEvent.bind(this);
    this.handleImageChange.bind(this);
    this.handleImageUpload.bind(this);
    this.handlePosterChange.bind(this);
    this.handlePosterUpload.bind(this);
    this.handleSaveDetails.bind(this);
    this.handleFestivalChange.bind(this);
    this.handleStartDateChange.bind(this);
    this.handleEndDateChange.bind(this);
    this.handleOpenFestivalDialog.bind(this);
    this.handleAssignFestivalToEvent.bind(this);
    this.handleRemoveFestivalFromEvent.bind(this);
  }

  componentDidMount() {
    if (this.state.id === 'new') {
      this.setState({event: eventController.getNew()});
    } else {
      this.fetchEvent();
    }
  }

  fetchEvent () {
    eventController.get(this.state.id)
      .then(event => {
        if (event.status === 404){
          this.setState({event: null, notFound: true});
          return;
        }
        this.setState({ event, notFound: false });
      })
      .catch(e => {
        this.setState({notFound: true});
        console.log(e);
      });
  }

  handleImageChange = e => {
    if (e.target.files[0]){
      const image = e.target.files[0];
      this.setState({
        uploadImage: image,
        previewImage: URL.createObjectURL(image),
      })
    }
  }

  handlePosterChange = e => {
    if (e.target.files[0]){
      const image = e.target.files[0];
      this.setState({
        uploadPoster: image,
        previewPoster: URL.createObjectURL(image),
      })
    }
  }

  handleImageUpload = () => {
    const image = this.state.uploadImage;
    if (image){
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(image);
    }
  }

  handlePosterUpload = () => {
    const image = this.state.uploadPoster;
    if (image){
      const reader = new FileReader();
      reader.onload = this._handlePosterLoaded.bind(this);
      reader.readAsBinaryString(image);
    }
  }

  sendImageRequest = (imageData) => {
    eventController.updateImage(this.state.id, imageData).then(() => {
      this.setState({snackbarMessage: 'Image Uploaded Successfully', snackbarOpen: true})
    }).catch(() => {
      this.setState({snackbarMessage: 'Image Failed to Save', snackbarOpen: true})
    });
  }

  sendPosterRequest = (imageData) => {
    eventController.updateLineupPoster(this.state.id, imageData).then(() => {
      this.setState({snackbarMessage: 'Poster Uploaded Successfully', snackbarOpen: true})
    }).catch(() => {
      this.setState({snackbarMessage: 'Poster Failed to Save', snackbarOpen: true})
    });
  }

  handleFieldChange = e => {
    let event = this.state.event;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    event[name] = value;
    this.setState({event});
  }

  handleFestivalChange = e => {
    let event = this.state.event;
    event.festival = e.target.value;
    this.setState({event});
  }

  handleStartDateChange = e => {
    let event = this.state.event;
    event.startDate = e;
    this.setState({event});
  }

  handleEndDateChange = e => {
    let event = this.state.event;
    event.endDate = e;
    this.setState({event});
  }

  handleSaveDetails = () => {
    if (this.state.event.name === null || this.state.event.name === ''){
      alert('Name and Location are required fields.')
      return;
    }

    const event = this.state.event;

    if (this.state.id === 'new'){
      eventController.add(event).then((newEvent) => {
        this.setState({id: newEvent.id, event: newEvent, snackbarMessage: 'Event Details Saved!', snackbarOpen: true});
      }).catch(e => console.log(e));
    } else {
      eventController.update(event).then(() => {
        this.setState({snackbarMessage: 'Event Details Saved!', snackbarOpen: true});
      }).catch(e => console.log(e));
    }
  }

  handleOpenFestivalDialog = () => {
    festivalController.getAll('',0,50)
      .then(page => {
        const festivals = page.items;
        this.setState({festivals, dialogOpen: true});
      });
  }

  handleCloseFestivalDialog = () => {
    this.setState({festivals: [], dialogOpen: false});
  }

  handleAssignFestivalToEvent = (festival) => {
    eventController.festival.add(this.state.id, festival.id)
      .then(() => {
        this.handleCloseFestivalDialog();
        this.fetchEvent();
      })
      .catch(e => console.log(e));
  }

  handleRemoveFestivalFromEvent = () => {
    eventController.festival.remove(this.state.id)
      .then(() => this.fetchEvent())
      .catch(e => console.log(e));
  }

  togglePublished = (e) => {
    const published = e.target.checked;
    const event = this.state.event;
    eventController.setPublished(this.state.id, published)
      .then(() => {
        event.published = published;
        this.setState({event, snackbarOpen: true, snackbarMessage: 'Publish settings updated'});
      }).catch(e => console.log(e));
  }

  render() {
    if (this.state.notFound){
      return (
        <NotFound entityType='Event' />
      );
    }

    if (this.state.event){
      const event = this.state.event;
      return (
        <>
          <Helmet>
            <title>{this.state.event.name} | Bass Gremlin</title>
          </Helmet>
          {this.state.id !== 'new' &&
            <>
              <CollapsibleCard sx={{marginBottom: 1}} defaultOpen={true} title={event.name} >
                <CardContent>
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}} >
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <Avatar
                          sx={{ width: 240, height: 240, margin: 2, fontSize: 'xxx-large' }}
                          src={this.state.previewImage ? this.state.previewImage : this.state.event.img}
                        >
                          {getInitials(this.state.event.name)}
                        </Avatar>
                        {this.state.previewPoster || this.state.event.lineupPoster ?
                          <a href={this.state.event.lineupPoster} target='_blank'>
                            <img alt={'Event Poster'} src={this.state.previewPoster ? this.state.previewPoster : this.state.event.lineupPoster} style={{height: 300}} />
                          </a>
                          : null }
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions >
                  <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', }}>
                    {this.state.previewImage ?
                      <Button variant="contained" onClick={this.handleImageUpload}>
                        Save Image
                      </Button>
                      :
                      <Button color="primary" variant="contained" onClick={() => this.inputReference.current.click()}>
                        Change Image
                      </Button>}
                    <input
                      type='file'
                      multiple={false}
                      accept='.jpg,.jpeg'
                      hidden
                      ref={this.inputReference}
                      onChange={this.handleImageChange} />
                    {this.state.previewPoster ?
                      <Button variant="contained" onClick={this.handlePosterUpload}>
                        Save Poster
                      </Button>
                      :
                      <Button color="primary" variant="contained" onClick={() => this.posterRef.current.click()}>
                        Change Poster
                      </Button>}
                    <input
                      type='file'
                      multiple={false}
                      accept='.jpg,.jpeg'
                      hidden
                      ref={this.posterRef}
                      onChange={this.handlePosterChange}
                    />
                  </Box>
                </CardActions>
              </CollapsibleCard>
            </>
          }
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form autoComplete="off" noValidate>
              <CollapsibleCard defaultOpen={true} title='Event Profile' sx={{marginBottom: 1}}>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <MediumTextInput name='name' label='Event Name' onChange={this.handleFieldChange}
                                     value={this.state.event.name} />
                    <MediumTextInput name='country' label='Country' onChange={this.handleFieldChange}
                                     value={this.state.event.country} />
                    <MediumTextInput name='city' label='City' onChange={this.handleFieldChange}
                                     value={this.state.event.city} />
                    <MediumTextInput name='state' label='State' onChange={this.handleFieldChange}
                                     value={this.state.event.state} />
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        helperText="About"
                        label="About"
                        name="about"
                        onChange={this.handleFieldChange}
                        value={this.state.event.about}
                        variant="outlined"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item md={3} xs={8}>
                      <DatePicker
                        name='startDate'
                        label="Start Date"
                        onChange={(newValue) => this.handleStartDateChange(newValue)}
                        value={this.state.event.startDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item md={3} xs={8}>
                      <DatePicker
                        name='endDate'
                        label="End Date"
                        onChange={(newValue) => this.handleEndDateChange(newValue)}
                        value={this.state.event.endDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item md={3} xs={8}>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Typography>Published</Typography>
                        <Switch size="large" checked={this.state.event.published} onChange={this.togglePublished} />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button color="primary" variant="contained" onClick={this.handleSaveDetails}>
                    Save details
                  </Button>
                </CardActions>
              </CollapsibleCard>
              <CollapsibleCard sx={{marginBottom: 1}} defaultOpen={false} title='Links'>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, margin: 2}}>
                  <TextField value={event.facebook ? event.facebook : ''} onChange={this.handleFieldChange} size="small" name="facebook" label="Facebook Link" sx={{marginBottom: 2}}/>
                  <TextField value={event.instagram ? event.instagram : ''} onChange={this.handleFieldChange} size="small" name="instagram" label="Instagram Link" sx={{marginBottom: 2}}/>
                  <TextField value={event.twitter ? event.twitter : ''} onChange={this.handleFieldChange} size="small" name="twitter" label="Twitter Link" sx={{marginBottom: 2}}/>
                  <TextField value={event.youTube ? event.youTube : ''} onChange={this.handleFieldChange} size="small" name="youTube" label="YouTube Link" sx={{marginBottom: 2}}/>
                  <TextField value={event.website ? event.website : ''} onChange={this.handleFieldChange} size="small" name="website" label="Website Link" sx={{marginBottom: 2}}/>
                  <TextField value={event.tickets ? event.tickets : ''} onChange={this.handleFieldChange} size="small" name="tickets" label="Tickets Link" sx={{marginBottom: 2}}/>
                  <TextField value={event.trailer ? event.trailer : ''} onChange={this.handleFieldChange} size="small" name="trailer" label="Trailer Link" sx={{marginBottom: 2}}/>
                </Box>
              </CollapsibleCard>
            </form>
            {
              this.state.id === 'new' ? null :
                <>
                <CollapsibleCard
                  sx={{marginBottom: 1}}
                  defaultOpen={false}
                  title={`Festival ${event.festival ? `(${event.festival.name})` : ''}`}
                >
                  <CardContent>
                    {
                      this.state.event.festival ?
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <Avatar
                            sx={{ width: 48, height: 48, margin: 2, fontSize: 'medium' }}
                            src={this.state.event.festival.img}
                          >
                            {getInitials(this.state.event.festival.name)}
                          </Avatar>
                          <NavLink to={`/app/festivals/${this.state.event.festival.id}`}>
                            <Typography>{this.state.event.festival.name}</Typography>
                            <Typography>{this.state.event.festival.about}</Typography>
                          </NavLink>
                          <IconButton color='primary' aria-label='delete' onClick={this.handleRemoveFestivalFromEvent}>
                            <Clear />
                          </IconButton>
                        </Box>
                        :
                        <Button variant='contained' onClick={this.handleOpenFestivalDialog}>Assign</Button>
                      }
                    </CardContent>
                  </CollapsibleCard>
                  <TagBox id={this.state.id} type='event' />
                  <EventLineup id={this.state.id}/>
                  <PerformanceList id={this.state.id} />
                  <PostList id={this.state.id} type='events' />
                </>
            }
            <Snackbar
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              open={this.state.snackbarOpen}
              autoHideDuration={3000}
              onClose={() => this.setState({snackbarOpen: false})}
              message={this.state.snackbarMessage}
            />
          </LocalizationProvider>
          <Dialog open={this.state.dialogOpen} onClose={() => this.setState({dialogOpen: false})}>
            <DialogTitle>Select Festival</DialogTitle>
            <DialogContent>
              <List sx={{width: '100%', maxWidth: 360, overflow: 'auto', maxHeight: 600}}>
                {this.state.festivals.map(festival =>
                  <ListItem key={festival.id}>
                    <Button onClick={() => this.handleAssignFestivalToEvent(festival)}>{festival.name}</Button>
                  </ListItem>
                )}
              </List>
            </DialogContent>
          </Dialog>
        </>
      );
    } else{
      return <></>
    }
  }

  _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.sendImageRequest(btoa(binaryString));
  }

  _handlePosterLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.sendPosterRequest(btoa(binaryString));
  }
}

const MediumTextInput = ({name, label, onChange, value}) => {
  return (
    <Grid item md={6} xs={12}>
      <TextField
        fullWidth
        label={label}
        name={name}
        onChange={onChange}
        required
        value={value}
        variant="outlined"
      />
    </Grid>
  );
}

export default EventDetails;
