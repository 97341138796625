import React, {useState} from "react";
import {differenceInMinutes, format} from "date-fns";
import {Box, Button, IconButton, Tooltip, Typography} from "@mui/material";
import {AddCircle, Clear} from "@mui/icons-material";

const performanceTextStyle = {
  backgroundColor: 'rgba(49,49,49,0.7)',
  color: 'white',
  paddingLeft: 1,
  paddingRight: 1,
  borderRadius: 1
}

const SlotButton = ({stage, time, openDialog, performances, deletePerformance, rowHeight, interval}) => {
  const [show, setShow] = useState(false);

  const utcTime = time.getTime();

  const performance = performances.find(x => x.stage.name === stage.name && new Date(x.startTime).getTime() === utcTime);
  const buttonBlocked = performances.find(x => x.stage.name === stage.name && new Date(x.startTime).getTime() <= utcTime && new Date(x.endTime).getTime() > utcTime);

  if (performance){
    const width = (1 / performance.artists.length) * 100;
    const displayWidth = width.toString() + '%';

    const indexToLeft = (index) => {
      return (index * width).toString() + '%';
    }

    const height = differenceInMinutes(new Date(performance.endTime), new Date(performance.startTime)) * (rowHeight / interval) ;
    return (
      <Box sx={{
        position: 'relative', top: 0, right: 0, left: 0,
        height: height,
        backgroundColor: 'purple',
        border: '1px solid purple'
      }}
      >
        {
          performance.artists.map((artist, index) =>
            <Box key={index} sx={{
              position: 'absolute',
              top: 0,
              left: `${indexToLeft(index)}`,
              height: '100%',
              width: `${displayWidth}`,
              backgroundImage: `url("${artist.img}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'top',
            }}
            />
          )
        }
        <Box sx={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', padding: 1}}>
          <Typography sx={{ ...performanceTextStyle }}>
            {performance.artists.map((artist, index) =>
              <span key={index}>{index > 0 && ' / '}{artist.name}</span>
            )}
          </Typography>
          <Typography sx={{ ...performanceTextStyle }}>{format(new Date(performance.startTime), 'p')} to {format(new Date(performance.endTime), 'p')}</Typography>
          <Tooltip title={'Delete Performance'}>
            <IconButton sx={{position: 'absolute', top: 0, right: 0}} onClick={() => deletePerformance(performance)}>
              <Clear sx={{color: 'white'}} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    )
  }

  if (buttonBlocked){
    return null;
  }

  return (
    <Button
      sx={{width: '100%', height: '100%'}}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={() => openDialog(time, stage)}
    >
      {show &&
      <>
        <AddCircle fontSize='small'/>
        <Typography>{format(time, 'p')}</Typography>
      </>
      }
    </Button>
  );
}

export default SlotButton;
