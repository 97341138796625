import React, {Component} from "react";
import {
  Avatar,
  Box, Button,
  Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow, TextField,
  Typography
} from '@mui/material';
import getInitials from "../../utils/getInitials";
import {NavLink} from "react-router-dom";
import {format} from "date-fns";
import { postController } from '../../controllers/postController';
import PerfectScrollbar from 'react-perfect-scrollbar';

class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        items: [],
        totalPages: 0,
        itemsFrom: 0,
        itemsTo: 0,
        totalItemsCount: 0
      },
      filter: '',
      pageSize: 15,
      pageNumber: 0,
      disconnected: false,
      dialogOpen: false,
      post: {
        title: '',
        body: '',
        entityId: props.id,
        entityType: props.type,
      }
    }
    this.id = props.id;
    this.type = props.type;
    this.handleLimitChange.bind(this);
    this.handlePageChange.bind(this);
    this.closeDialog.bind(this);
    this.changePostValue.bind(this);
    this.createPost.bind(this);
    this.fetchPosts.bind(this);
  }

  componentDidMount() {
    this.fetchPosts(this.state.filter, this.state.pageNumber, this.state.pageSize);
  }

  fetchPosts(filter, pageNumber, pageSize) {
    postController.getAll(this.type, this.id, filter, pageNumber, pageSize)
      .then(page => {
        this.setState({page, disconnected: false});
      })
      .catch(e => {
        this.setState({disconnected: true})
        console.log(e);
      });
  }

  handleLimitChange = e => {
    this.setState({limit: e.target.value});
    this.fetchPosts(this.state.filter, this.state.pageNumber, e.target.value);
  }

  handlePageChange = (e, newPage) => {
    this.setState({pageNumber: newPage});
    this.fetchPosts(this.state.filter, newPage, this.state.pageSize);
  }

  closeDialog = () => {
    this.setState({post: {
        title: '',
        body: '',
        entityId: this.id,
        entityType: this.type,
      }, dialogOpen: false});
  }

  changePostValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const post = this.state.post;
    post[name] = value;
    this.setState({post});
  }

  createPost = () => {
    postController.add(this.state.post)
      .then(() => {
        this.closeDialog();
        this.fetchPosts(this.state.filter, this.state.pageNumber, this.state.pageSize);
      }).catch(e => console.error(e));
  }

  render() {
    const page = this.state.page;

    return (
      <>
        <Card>
          <CardHeader
            title='Posts'
            action={
              <Button variant='contained' color='primary' onClick={() => this.setState({dialogOpen: true})}>Create Post</Button>
            }
          />
          <PerfectScrollbar>
            <Box sx={{minWidth: 800}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Body</TableCell>
                    <TableCell>CommentCount</TableCell>
                    <TableCell>PlurCount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {page.items.map((post) => (
                    <TableRow hover key={post.id}>
                      <TableCell>
                        <Box sx={{alignItems: 'center', display: 'flex'}}>
                          <Avatar src={post.userThumbnail} sx={{ mr: 2 }}>
                            {getInitials(post.username)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            <NavLink to={`/app/users/${post.username}`} style={{width: '100%'}}>
                              {post.username}
                            </NavLink>
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {`${format(new Date(post.createdAt), 'P')}`}
                      </TableCell>
                      <TableCell>
                        <Typography variant='h5'>{post.title}</Typography>
                        <Typography>{post.body}</Typography>
                      </TableCell>
                      <TableCell>{post.commentCount}</TableCell>
                      <TableCell>{post.plurCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={page.totalItemsCount}
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleLimitChange}
            page={this.state.pageNumber}
            rowsPerPage={this.state.pageSize}
            rowsPerPageOptions={[5, 15, 50]}
          />
        </Card>
        <Dialog open={this.state.dialogOpen} onClose={this.closeDialog}>
          <DialogTitle sx={{marginBottom: 1}}>New Post</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              sx={{minWidth: 300, marginBottom: 2, marginTop: 1}}
              name='title'
              value={this.state.post.title}
              onChange={this.changePostValue}
              variant='outlined'
              label='Title'
            />
            <TextField
              fullWidth
              sx={{minWidth: 300}}
              name='body'
              value={this.state.post.body}
              onChange={this.changePostValue}
              variant='outlined'
              label='Body'
              multiline
              rows={6}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.createPost}>Post</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default PostList;
