import React, {Component} from "react";
import {
  Autocomplete,
  Avatar,
  Box, Button,
  Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select,
  Table,
  TableBody,
  TableCell,
  TableHead, TablePagination,
  TableRow, TextField,
  Typography
} from '@mui/material';
import PerfectScrollbar from "react-perfect-scrollbar";
import getInitials from "../../utils/getInitials";
import format from 'date-fns/format';
import { DateTimePicker } from '@mui/lab';
import {NavLink} from "react-router-dom";
import {eventController} from "../../controllers/eventController";
import {artistController} from "../../controllers/artistController";
import CollapsibleCard from '../helpers/CollapsibleCard';
import {SocialLinks} from "../social/SocialLinks";
import {Add} from "@mui/icons-material";

class PerformanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      performances: [],
      limit: 15,
      page: 0,
    }
    this.id = props.id;
    this.handleLimitChange.bind(this);
    this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.fetchPerformances();
  }

  fetchPerformances(){
    eventController.performances.get(this.id)
      .then(result => {
        this.setState({performances: result})
      })
      .catch(e => console.log(e));
  }

  handleLimitChange = e => this.setState({limit: e.target.value});

  handlePageChange = (e, newPage) => this.setState({page: newPage});

  render() {
    const performances = this.state.performances;
    const page = this.state.page;
    const limit = this.state.limit;

    return (
        <CollapsibleCard
          title={
            <>
            <span style={{marginRight: 30}}>Schedule</span>
            <NavLink to={`/app/events/${this.id}/schedule`}>
              <Button variant='contained'>Schedule Editor</Button>
            </NavLink>
            </>
          }
          defaultOpen={false}
          sx={{marginBottom: 1}}
        >
          <PerfectScrollbar>
            <Box sx={{minWidth: 800}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Artists</TableCell>
                    <TableCell>Stage</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {performances.slice(page * limit, limit + page * limit).map((performance) => (
                    <TableRow hover key={performance.id}>
                      <TableCell>
                        <Box sx={{alignItems: 'center', display: 'flex'}}>
                          <Typography>
                            {performance.artists.map((artist, index) =>
                              <>
                                {index > 0 && <Add />}
                                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                  <Avatar src={artist.thumbnail} sx={{ mr: 2, width: 48, height: 48 }}>
                                    {getInitials(artist.name)}
                                  </Avatar>
                                  <Typography color="textPrimary" variant="body1" sx={{marginRight: 1}}>
                                    <NavLink to={`/app/artists/${artist.id}`} style={{width: '100%'}}>
                                      {artist.name}
                                    </NavLink>
                                    <SocialLinks entity={artist} size={22} />
                                  </Typography>
                                </Box>
                              </>
                            )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{performance.stage.name}</TableCell>
                      <TableCell>{format(new Date(performance.startTime), 'P')}</TableCell>
                      <TableCell>{format(new Date(performance.startTime), 'p')}</TableCell>
                      <TableCell>{format(new Date(performance.endTime), 'p')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={performances.length}
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 15, 50]}
          />
        </CollapsibleCard>
    );
  }
}

export default PerformanceList;
