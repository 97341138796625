import {NavLink} from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card, Checkbox, CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import getInitials from '../../utils/getInitials';
import {format} from "date-fns";
import { SocialLinks } from '../social/SocialLinks';
import Pagination from '../helpers/Pagination';

const EventListResults = ({ page, pageSize, pageNumber, handlePageSizeChange, handlePageNumberChange, loading, ...rest }) => {

  const handleLimitChange = (e) => {
    handlePageSizeChange(e.target.value);
  };

  const handlePageChange = (e, newPage) => {
    handlePageNumberChange(newPage);
  };

  if (loading){
    return <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
      <CircularProgress />
    </Box>
  }

  return (
    <Card {...rest}>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Dates</TableCell>
                <TableCell>PlurCount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.items.map((event) => (
                <TableRow hover key={event.id}>
                  <TableCell>
                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                      <Avatar src={event.thumbnail} sx={{ mr: 2 }}>
                        {getInitials(event.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        <NavLink to={`/app/events/${event.id}`} style={{width: '100%'}}>
                          {event.name}
                        </NavLink>
                        <SocialLinks entity={event} size={22} />
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Checkbox disabled checked={event.published} />
                  </TableCell>
                  <TableCell>
                    {`${event.city}, ${event.state}, ${event.country}`}
                  </TableCell>
                  <TableCell>
                    {
                      event.startDate && event.endDate ?
                      `${format(new Date(event.startDate), 'P')} - ${format(new Date(event.endDate), 'P')}` : ''
                    }
                  </TableCell>
                  <TableCell>{event.plurCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
    </Card>
  );
};

export default EventListResults;
