import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card, CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {Lock} from 'react-feather';
import getInitials from '../../utils/getInitials';
import Pagination from '../helpers/Pagination';
import { NavLink } from 'react-router-dom';

const UserListResults = ({page, setPageSize, setPageNumber, pageSize, pageNumber, loading, ...rest }) => {

  const handleLimitChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPageNumber(newPage);
  };

  if (loading){
    return <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
      <CircularProgress />
    </Box>
  }

  return (
    <Card {...rest}>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Private</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.items.map((user) => (
                <TableRow hover key={user.username}>
                  <TableCell>
                    <NavLink to={`/app/users/${user.username}`}>
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                          <Avatar src={user.thumbnail} sx={{ mr: 2 }}>
                            {getInitials(user.username)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {user.username}
                          </Typography>
                      </Box>
                  </NavLink>
                  </TableCell>
                  <TableCell>{user.private ? <Lock /> : null}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Pagination
        pageNumber={pageNumber}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
      />
    </Card>
  );
};

export default UserListResults;
